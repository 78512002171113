import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from "react-scroll"
import { StyledNav } from './styles'

const MenuHome = (props) => {
  const {
    list,
  } = props

  const [currentTab, setCurrentTab] = useState('1')

  useEffect(() => {
    window.addEventListener('scroll', () => {
      let value
      const height = window.innerHeight

      if (window.scrollY < height - 200) {
        value = '1'
      }

      if (window.scrollY > height - 200) {
        value = '2'
      }

      if (window.scrollY > (height * 2) - 200) {
        value = '3'
      }

      if (window.scrollY > (height * 3) - 200) {
        value = '4'
      }

      setCurrentTab(value)
    })
  }, [])

  const handleClick = (id) => {
    setCurrentTab(id)
  }

  const renderContent = ({
    description,
    id,
    title,
    link,
  }) => (
    <li key={id} className={id === currentTab ? 'active' : ''}>
      <Link
        to={link}
        smooth={true}
        className={id === currentTab ? 'active' : ''}
        onClick={() => handleClick(id)}
        title={title}
      >
        <span> {id} </span>
        {title}
      </Link>
      <div>
        <p> {description} </p>
      </div>
    </li>
  )

  return (
    <StyledNav>
      <ul>
        {list.map(renderContent)}
      </ul>
    </StyledNav>
  )
}

MenuHome.propTypes = {
  list: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default MenuHome
