import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { flickrRequest } from '@/api/request-flickr'
import ImageGallery from 'react-image-gallery'
import Aside from './AsidePhotos'
import PinchZoomPan from 'react-responsive-pinch-zoom-pan'
import {
  Loading,
  SectionPhoto,
} from './styles.js'
import Footer from '../Footer'

const OPTIONS = {
  defaultCode: '72157713752297391',
  fixedURL: 'https://farm66.staticflickr.com',
}

const Photos = (props) => {
  const { isMobile } = props

  const [zoomImg, setZoomImg] = useState(null)
  const [showZoom, setShowZoom] = useState(false)
  const [showImages, setShowImages] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [panoramaPage, setPanoramaPage] = useState(false)

  const fullScreen = React.createRef()

  const setImagens = (data) => {
    const innerWidth = window.innerWidth
    const imgType = innerWidth <= 980 ? 'k' : 'o'
    const originalSize = `${OPTIONS.fixedURL}/${data.server}/${data.id}_${data.originalsecret}_${imgType}.jpg`
    const squareSize = `${OPTIONS.fixedURL}/${data.server}/${data.id}_${data.secret}_q.jpg`

    return {
      original: originalSize,
      thumbnail: squareSize
    }
  }

  const getPhoto = useCallback(async(photoList) => {
    const photos = [...showImages]
    await photoList.map((photo) => {
      const props = {
        order: 'getPhotoInfo',
        code: photo.id
      }

      setLoading(true)
       flickrRequest(props)
        .then(resp => {
          const unit = setImagens(resp.data.photo)
          photos.push(unit)
          setShowImages(photos)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
      
    })
  }, [])

  const albumRequest = useCallback((code) => {
    const props = {
      order: 'getAlbum',
      code
    }

    setLoading(true)

    flickrRequest(props)
      .then( resp => {
        getPhoto(resp.data.photoset.photo)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    albumRequest(OPTIONS.defaultCode)
  }, [])

  const onItemClick = (code) => {
    setShowImages([])
    albumRequest(code)
  }

  const onFullScreenClick = () => {
    if (showZoom) {
      return setShowZoom(false)
    }

    let value = selectedIndex

    if (!selectedIndex) {
      setSelectedIndex(0)
      value = 0
    }

    setShowZoom(true)
    setZoomImg(showImages[value].original)
  }

  const renderFullscreenButton = () => (
    <button
      type='button'
      className={
        `image-gallery-fullscreen-button${showZoom ? ' active' : ''}`}
      onClick={onFullScreenClick}
    >
      {showZoom ? (
        <i className="far fa-window-close" />
      ) : (
        <i className="fas fa-expand" />
      )}
    </button>
  )


  const showZoomImg = () => (
    <div className='img_zoom_container'>
      {renderFullscreenButton()}
      <PinchZoomPan
        maxScale={2}
        position={'center'}
        zoomButtons={false}
      >
        <img src={zoomImg} alt='zoomed photography'/>
      </PinchZoomPan>
    </div>
  )

  const configParnoramaPage = (panoramaPage) => {
    setPanoramaPage(panoramaPage)
  }

  const getImgIndex = (e) => {
    setSelectedIndex(e)
  }

  const renderLoading = () => (
    <Loading>
      <i className="fas fa-spinner" />
    </Loading>
  )

  return (
    <SectionPhoto>
      {loading && renderLoading()}
      {showZoom && showZoomImg()}
      <Aside
        getAlbumCode={onItemClick}
        panoramaPage={configParnoramaPage}
        isMobile={isMobile}
      />
      <article className={panoramaPage ? 'panorama' : ''}>
        {showImages?.length ? (
          <>
            <ImageGallery
              items={showImages}
              showFullscreenButton={false}
              ref={fullScreen}
              onSlide={getImgIndex}
              onClick={onFullScreenClick}
            />
            {renderFullscreenButton()}
          </>
        ) : (
          <div className='no_album_found'>Album ou Imagem não encontrados!</div>
        )}
      </article>
      <Footer />
    </SectionPhoto>
  )
}

Photos.propTypes = {
  isMobile: PropTypes.bool.isRequired,
}

export default Photos
