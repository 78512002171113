import React, { useState } from 'react'
import PropTypes from 'prop-types'
import BreadCrumb from '@/components/fragments/BreadCrumb'
import Footer from '../Footer'
import { format } from 'date-fns'
import { POSTS } from '@/constants/mocks/post'
import {
  StyledGrid,
  StyledArticle,
} from './styles'

const URL = '/imgs/Temp/blog/'

const Blog = (props) => {
  const { isMobile } = props
  const [postToRender, setPostToRender] = useState(null)

  const getPost = () => {
    if (!postToRender) return POSTS[0]

    return POSTS.find((item) => item.ID === postToRender)
  }

  const setupPost = (post) => {
    if(!post.post_publish) return

    return (
      <StyledArticle
        className="postBox"
        key={post.ID}
      >
        <header>
          <h2>{post.post_title}</h2>
          <div>
            <i className="fas fa-calendar-alt" />
            {format(new Date(post.post_date), "dd/MM/yyyy HH'h'mm")}
          </div>
        </header>
        <div
          className="thumb"
          style={{backgroundImage: `url(${URL}${post.post_thumb})`}}
        />
        <div className="bottom">
          <p dangerouslySetInnerHTML={{__html: post.post_content}} />
        </div>
      </StyledArticle>
    )
  }

  const renderOnePost = () => {
    const post = getPost()

    return setupPost(post)
  }

  const renderAllPosts = (post) => (
    setupPost(post)
  )

  const handleClickMenu = (id) => {
    setPostToRender(id)
  }

  const renderAside = (post) => {
    if(!post.post_publish) return

    const activeThis = postToRender ? postToRender : POSTS[0].ID

    return (
      <li key={post.ID}>
        <a
          onClick={() => handleClickMenu(post.ID)}
          className={activeThis === post.ID ? 'active' : ''}
        >
          <div
            className="thumb"
            style={{backgroundImage: `url(${URL}${post.post_thumb})`}}
          />
          <div className='title'>
            <div>
              <p>
                <span>{post.post_title}</span>
                <span>{format(new Date(post.post_date), "dd/MM/yyyy HH'h'mm")}</span>
              </p>
            </div>
          </div>
        </a>
      </li>
    )
  }

  return (
    <StyledGrid>
        <BreadCrumb
          title='Blog'
        />
        {!isMobile && (
          <aside>
            <ul>
              {POSTS.map((post) => (renderAside(post)))}
            </ul>
          </aside>
        )}
        <div className="boxPostContainer">
          {isMobile ? (
            POSTS.map((post) => renderAllPosts(post))
          ) : (
            renderOnePost()
          )}
        </div>
        <Footer />
    </StyledGrid>
  )
}

Blog.propTypes = {
  isMobile: PropTypes.bool.isRequired,
}

export default Blog
