import styled from '@emotion/styled'

export const StyledContainer = styled.div`
  width: 100%;
  margin: 30px 0 70px;
  background: rgb(255 255 255 / 80%);

  .top {
    font-size: 24px;
    min-height: 50px;
    display: block;
    padding: 10px;
    text-align: center;
    color: var(--color-light);
    font-family: Alternate;
    background: var(--color-bg-dark2);
    width: 100%;
    text-transform: uppercase;
  }

  .bottom {
    nav {
      margin-bottom: 30px;
      padding: 5px 0;
      border-bottom: 1px solid var(--color-bg-dark2);
      display: grid;
      grid-template-columns: repeat(4, calc(25% - .75px));
      grid-gap: 1px;

      button {
        background-color: transparent;
        border: none;
        font-size: 18px;
        height: 42px;
        float: left;
        font-family: AgencyFB;
        text-align: center;
        line-height: 42px;
        text-transform: uppercase;
        padding: 0;
        margin: 0;

        &:hover, &.active {
          background: var(--color-bg-dark2);
          color: var(--color-light);
        }
      }
    }

    ul {
      li {
        img {
          float: left;
          margin: 0 20px 10px;
          max-width: inherit;
          width: 150px;
        }

        & > div {
          font-size: 19px;
          line-height: 25px;
          font-family: AgencyFB;
          color: var(--color-bg-dark2);
          padding: 10px 10px 20px;
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .top {
      font-size: 36px;
    }

    .bottom {
      nav {
        button {
          font-size: 26px;
        }
      }
      ul {
        li {
          img {
            margin-bottom: 20px;
          }
          & > div {
            font-size: 26px;
            line-height: 31px;
          }
        }
      }
    }
  }
`
