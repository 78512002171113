import styled from '@emotion/styled'

export const Loading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: var(--color-hover-black-dark);
  display: flex;
  justify-content: center;
  align-items: center;

  @keyframes Loading {
    from {
      transform: rotate(0deg);
      color: var(--color-bg-dark2);
    }
    to {
      transform: rotate(360deg);
      color: var(--color-primary);
    }
  }

  i.fa-spinner {
    font-size: 70px;
    margin: 50px auto;
    display: block;
    width: 70px;
    color: var(--color-primary);
    animation: Loading infinite 1.5s linear;
  }
`

export const SectionPhoto = styled.section`
  padding-top: 70px;

  article{
    width: 100%;
    padding: 20px 5px;
    position: relative;
    height: 100%;
    margin-bottom: 30px;
    background-color: #222222;

    &.panorama {
      .image-gallery-content {
        .image-gallery-slide {
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    .image-gallery-content {
      .image-gallery-slide img {
        width: 100%;
        height: auto;
        min-height: 200px;
      }

      &.fullscreen {
        .image-gallery-slide {
          img {
            height: 100vh;
          }
        }
      }
    }

    .image-gallery,
    .image-gallery-content.fullscreen,
    .image-gallery-slide {
      background-color: #222;
    }

    .image-gallery-slide.center{
      z-index: 3;
    }
  }

  .no_album_found {
    width: calc(100% - 40px);
    font-size: 40px;
    margin: 20px;
    padding: 100px 20px;
    position: relative;
    text-align: center;
    border: 2px dashed;
  }

  .image-gallery-fullscreen-button {
    bottom: auto;
    top: 20px;
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    position: absolute;
    z-index: 4;
    color: var(--color-light);
    font-size: 25px;

    &.active {
      top: 0;
    }

    &:hover {
      color: var(--color-primary);
    }
  }

  .image-gallery-icon:hover {
    color: var(--color-primary);
  }

  .img_zoom_container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    text-align: center;
    background-color: #222222;
  }

  @media (min-width: 760px) {
    background-color: #222;

    article {
      width: calc(100% - 220px);
      display: inline-block;
      margin-left: 20px;
      padding: 0 15px 0 0;
    }

    .image-gallery-fullscreen-button {
      right: 20px;
      font-size: 40px;
      top: 0;
    }
  }

  @media (min-width: 1020px) {
    padding-top: 90px;

    article {
      width: calc(100% - 320px);
    }
  }

  @media (min-width: 1500px) {
    padding-top: 100px;

    .image-gallery-content {
      .image-gallery-slide { 
        .image-gallery-image {
           max-height: calc(100vh - 220px);
        }
      }
    }
  }
`
