import styled from '@emotion/styled'

export const StyledGrid = styled.section`
  padding-top: 48px;

  .boxPostContainer {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 30px;
  }

  & > aside {
    width: 280px;
    display: inline-block;
    vertical-align: top;
    margin: 0 10px;
    padding: 0 0 30px;

    ul {
      li {
      margin-bottom: 3px;

        a {
          background-color: var(--color-gray-dark);
          display: block;
          width: 100%;
          padding: 1px;
          cursor: pointer;
          transition: all .1s linear;
          color: #111;

          & > div {
            &.thumb {
              width: 80px;
              height: 80px;
              display: inline-block;
              vertical-align: top;
              background-size: cover;
              background-posiction: center;
            }

            &.title {
              display: inline-block;
              width: calc(100% - 80px);

              & > div {
                display: flex;
                justify-content: left;
                align-items: center;
                padding: 0px 10px;
                height: 80px;

                p {
                  span {
                    display: block;

                    &:first-of-type {
                      text-transform: uppercase;
                      font-size: 13px;
                      margin-bottom: 3px;
                      font-weight: 700;
                    }

                    &:last-of-type {
                      font-size: 10px;
                    }
                  }
                }
              }
            }
          }

          &.active {
            background-color: var(--color-primary);
            color: #111 !important;
          }

          &:hover {
            color: var(--color-primary);
            transition: all .1s linear !important;
          }
        }
      }
    }
  }

  @media (min-width: 760px) {
    .boxPostContainer {
      width: calc(100% - 310px);
      display: inline-block;
    }
  }

  @media (min-width: 1020px) {
    padding-top: 70px;

    & > aside {
      margin: 0 20px;
    }

    .boxPostContainer {
      width: calc(100% - 340px);
    }
  }

  @media (min-width: 1500px) {
    padding-top: 80px;
  }
`

export const StyledArticle = styled.article`
  background: rgba(255,255,255,.5);
  margin-bottom: 20px;
  padding: 20px 15px;

  header {
    & > h2 {
      color: var(--color-bg-dark2);
      font-size: 26px;
      line-height: 33px;
      text-align: center;
    }
  
    & > div {
      border-bottom: 1px solid rgba(0, 0, 0, .3);
      border-top: 1px solid rgba(0, 0, 0, .3);
      margin: 10px 0 25px;
      padding: 13px 0;
      text-align: center;

      i {
        margin-right: 10px;
      }
    }
  }

  .thumb {
    background-position: top center;
    background-size: cover;
    height: 460px;
    margin-bottom: 20px;
    width: 100%;
  }

  .bottom {
    line-height: 23px;

    img {
      margin: 10px;
    }
  }

  @media (min-width: 1500px) {
    padding: 20px 50px;
  }
`
