import React, { useState } from 'react'
import PropTypes from 'prop-types'

const BriefingSite = (props) => {
  const {
    onSubmit,
  } = props

  const [form, setForm] = useState({})

  const onChangeInputValue = (e) => {
    const { value, name} = e.target

    setForm({
      ...form,
      [name]: value,
    })
  }

  const setupComponent = () => (`
    Qual o nome do seu site?<br> 
    ${form.siteName || 'N/D'}<br><br>
    Este site já tem um domínio próprio? <br>
    ${form.domain || 'N/D'}<br><br>
    Este site já tem hospedagem? <br>
    ${form.host || 'N/D'}<br><br>
    Já existe uma arte de logo para o site? <br>
    ${form.logo || 'N/D'}<br><br>
    Qual o tipo de site? <br>
    ${form.siteType || 'N/D'}<br><br>
    Te ofereço 5 páginas convencionais (home, quem somos, serviços, portfólio e contato). Existem outras de seu interesse? <br>
    ${form.morePages || 'N/D'}<br><br>
    Tem algum site na internet que queira dar de exemplo como layout para o seu? <br>
    ${form.references || 'N/D'}<br><br>
    Após a conclusão do site, quem gerenciará o conteúdo? <br>
    ${form.whoIsAdmin || 'N/D'}
  `)

  const submitForm = (e) => {
    e.preventDefault()
    const content = setupComponent()
    const formContent = {
      briefing_type: 'Site',
      from_name: form.Name,
      reply_to: form.Email,
      briefing_contents: content,
    }

    onSubmit(formContent)
  }

  return (
    <form>
      <label className='label'>
        <span>Nome <b>*</b></span>
        <input
          name='Name'
          type='text'
          className='input'
          onChange={onChangeInputValue}
        />
      </label>

      <label className='label'>
        <span>E-mail <b>*</b></span>
        <input
          name='Email'
          type='email'
          className='input'
          onChange={onChangeInputValue}
        />
      </label>

      <label className='label'>
        <span>Qual o nome do seu site? <b>*</b></span>
        <input
          name='siteName'
          type='text'
          className='input'
          onChange={onChangeInputValue}
        />
      </label>

      <div className='radio label'>
        <span>Este site já tem um domínio próprio?</span>
        <label className='label-radio'>
          <input
            name='domain'
            value='Sim'
            type='radio'
            onChange={onChangeInputValue}
          />
          Sim
        </label>
        <label className='label-radio'>
          <input
            name='domain'
            value='Não'
            type='radio'
            onChange={onChangeInputValue}
          />
          Não
        </label>
      </div>

      <div className='radio label'>
        <span>Este site já tem hospedagem?</span>
        <label className='label-radio'>
          <input
            name='host'
            value='Sim'
            type='radio'
            onChange={onChangeInputValue}
          />
          Sim
        </label>
        <label className='label-radio'>
          <input
            name='host'
            value='Não'
            type='radio'
            onChange={onChangeInputValue}
          />
          Não
        </label>
      </div>

      <div className='radio label'>
        <span>Já existe uma arte de logo para o site?</span>
        <label className='label-radio'>
          <input
            name='logo'
            value='Sim'
            type='radio'
            onChange={onChangeInputValue}
          />
          Sim
        </label>
        <label className='label-radio'>
          <input
            name='logo'
            value='Não'
            type='radio'
            onChange={onChangeInputValue}
          />
          Não
        </label>
      </div>

      <div className='radio label'>
        <span>Qual o tipo de site?</span>
        <label className='label-radio'>
          <input
            name='siteType'
            value='Institucional'
            type='radio'
            onChange={onChangeInputValue}
          />
          Institucional (padrão de 5 páginas)
        </label>
        <label className='label-radio'>
          <input
            name='siteType'
            value='Catálogo'
            type='radio'
            onChange={onChangeInputValue}
          />
          Catálogo
        </label>
      </div>

      <label className='label'>
        <span>Te ofereço 5 páginas convencionais (home, quem somos, serviços, portfólio e contato). Existem outras de seu interesse?</span>
        <input
          name='morePages'
          type='text'
          className='input'
          onChange={onChangeInputValue}
        />
      </label>

      <label className='label'>
        <span>Tem algum site na internet que queira dar de exemplo como layout para o seu?</span>
        <input
          name='references'
          type='text'
          className='input'
          onChange={onChangeInputValue}
        />
      </label>

      <div className='radio label'>
        <span>Após a conclusão do site, quem gerenciará o conteúdo?</span>
        <label className='label-radio'>
          <input
            name='whoIsAdmin'
            value='Você ou alguém da sua equipe'
            type='radio' onChange={onChangeInputValue}
          />
          Você ou alguém da sua equipe
        </label>
        <label className='label-radio'>
          <input
            name='whoIsAdmin'
            value='Eu - Ivan Oliveira'
            type='radio'
            onChange={onChangeInputValue}
          />
          Eu - Ivan Oliveira
        </label>
      </div>

      <button className='button' onClick={submitForm}>
        Enviar
      </button>
    </form>
  )
}

BriefingSite.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default BriefingSite
