import styled from '@emotion/styled'

export const StyledGrid = styled.div`
  padding-top: 48px;

  & > article {
    & > div {
      display: grid;
      grid-template-columns: 100%;
      grid-gap: 20px;
      padding: 20px;
      background-color: var(--color-gray-light);
      margin-bottom: 30px;
    }
  }

  @media (min-width: 600px) {
    & > article {
      & > div {
        grid-template-columns: repeat(2, calc(50% - 10px));
      }
    }
  }

  @media (min-width: 760px) {
    & > article {
      padding: 0 20px;
    }
  }

  @media (min-width: 1020px) {
    padding-top: 70px;

    & > article {
      padding: 0 30px;

      & > div {
        grid-template-columns: repeat(3, calc(33.3333333% - 20px));
        grid-gap: 30px;
        padding: 30px;
      }
    }
  }

  @media (min-width: 1510px) {
    padding-top: 80px;
  }
`

export const StyledBox = styled.div`
  width: 100%;
  min-height: 350px;
  border: 1px solid var(--color-bg-dark2);
  background-size: cover;
  background-position: center;
  position: relative;

  & > div {
    position:absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(65, 67, 68, 0.9);
    color: var(--color-secondary);
    font-family: 'AgencyFB';
    padding: 10px;

    p {
      width: calc(100% - 100px);
      display: inline-block;
    }

    a {
      background-color: var(--color-secondary);
      cursor: pointer;
      padding: 5px;
      display: block;
      font-size: 15px;
      width: 100px;
      display: inline-block;
      color: var(--color-bg-dark2);
      text-align: center;

      i {
        margin-left: 10px;
      }

      &:hover {
        color: var(--color-primary);
        background-color: var(--color-bg-dark);
      }
    }
  }
`
