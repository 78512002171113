import styled from '@emotion/styled'
import Bg001 from '@/imgs/bgHome01.jpg'
import Bg002 from '@/imgs/bgHome02.jpg'
import Bg003 from '@/imgs/bgHome03.jpg'
import Bg004 from '@/imgs/bgHome04.jpg'

export const SectionHome = styled.section`
  display: block;

  @media (min-width: 760px) {
    height: 100%;
  }
`

export const Bloco1 = styled.section`
  padding: 0 10px;
  background-image: url(${Bg001});
  background-position: 100% 100%;
  background-size: 73%;
  background-repeat: no-repeat;
  background-color: #d9e4d3;
  min-height: 320px;
  border-bottom: 2px solid var(--color-bg-dark2);

  .text {
    display: block;
    font-family: AgencyFB;
    color: #3e3e3e;
    font-size: 25px;
    line-height: 33px;
    text-align: left;
    padding: 70px 0 122px;
  }

  @media (min-width: 512px) {
    .text {
      padding: 111px 35% 200px 10px;
      font-size: 30px;
    }
  }

  @media (min-width: 760px) {
    height: 100%;
  }
`

export const Bloco2 = styled.section`
  background-image: url(${Bg002});
  background-position: 50%;
  background-size: cover;
  padding: 0 10px;
  border-bottom: 2px solid var(--color-bg-dark2);

  .text {
    text-align: center;
    padding: 50px 0 0;
    display: block;
    font-family: AgencyFB;
    color: #3e3e3e;
    font-size: 25px;
    line-height: 33px;
  }

  .boxInfo {
    margin: 30px 0 70px;
    background: rgb(255 255 255 / 80%);

    & > p {
      &.top {
        font-size: 24px;
        height: 50px;
        line-height: 55px;
        display: block;
        text-align: center;
        color: #fff;
        font-family: Alternate;
        background: var(--color-bg-dark2);
        width: 100%;
        text-transform: uppercase;
      }
    }

    div {
      font-family: AgencyFB;

      &.bottom {
        padding: 20px;

        .info {
          max-width: 270px;
          margin: 0 auto;

          p {
            color: var(--color-bg-dark2);
            font-size: 24px;
            display: inline-block;

            &:first-of-type {
              width: 66px;
              text-align: right;
            }

            &:nth-of-type(2) {
              font-size: 46px;
              width: calc(100% - 66px);
              text-align: center;
              margin-bottom: 2px;
            }
          }
        }

        .meio {
          width: 100%;
          margin: 20px 0;
          text-align: center;
          line-height: 30px;
          font-size: 64px;
          color: var(--color-bg-dark2);
        }

        .conteudo {
          margin-top: 20px;
          padding-top: 20px;
          border-top: 1px solid var(--color-bg-dark2);
          text-align: center;

          & > p {
            font-size: 23px;
            line-height: 25px;
          }
        }
      }
    }
  }

  @media (min-width: 650px) {
    .boxInfo {
      .bottom {
        .group {
          display: flex; 
          justify-content: center;
          align-items: center;

          .meio {
            width: 30px;
          }
        }
      }

      .conteudo {
        p {
          text-align: left;
        }
      }
    }
  }

  @media (min-width: 760px) {
    height: 100%;
    display: flex; 
    justify-content: right;
    align-items: center;

    .boxInfo {
      float: right;
      width: calc(100% - 330px);
    }
  }

  @media (min-width: 1280px) {
    .boxInfo {
      width: calc(100% - 470px);
    }
  }
`

export const Bloco3 = styled.section`
  padding: 0 10px;
  background-image: url(${Bg003});
  background-position: 50%;
  background-size: cover;
  border-bottom: 2px solid var(--color-bg-dark2);

  .text {
    text-align: center;
    padding: 50px 0 0;
    display: block;
    font-family: AgencyFB;
    color: #3e3e3e;
    font-size: 25px;
    line-height: 33px;
  }

  @media (min-width: 760px) {
    height: 100%;
    display: flex; 
    justify-content: right;
    align-items: center;

    & > div {
      float: right;
      width: calc(100% - 330px);
    }
  }

  @media (min-width: 1280px) {
    & > div {
      width: calc(100% - 470px);
    }
  }
`

export const Bloco4 = styled.section`
  padding: 0 10px;
  background-image: url(${Bg004});
  background-position: 50%;
  background-size: cover;
  border-bottom: 2px solid var(--color-bg-dark2);

  .text {
    text-align: center;
    padding: 50px 0 0;
    display: block;
    font-family: AgencyFB;
    color: #3e3e3e;
    font-size: 25px;
    line-height: 33px;
  }

  & > div {
    margin-top: 40px;
    width: 100%;
    font-family: AgencyFB;
    display: flex; 
    justify-content: right;
    align-items: center;
    padding: 0 30px;

    & > div {
      max-width: 400px;
      text-align: right;

      img {
        width: 100%;
        max-width: 400px;
      }

      & > p {
        font-size: 22px;
        margin-bottom: 25px;
        text-align: right;
      }

      & > a {
        background-color: var(--color-bg-dark2);
        color: var(--color-gray-light);
        font-size: 20px;
        padding: 10px 20px;
        margin-bottom: 70px;
        display: inline-block;

        i {
          margin-left: 5px;
        }
      }
    }
  }

  @media (min-width: 760px) {
    height: 100%;
    display: flex; 
    justify-content: right;
    align-items: center;

    & > div {
      float: right;
      width: calc(100% - 330px);
    }
  }

   @media (min-width: 1280px) {
    align-items: end;

    & > div {
      width: calc(100% - 470px);
      max-width: 800px;
      padding: 0 50px 0 0;

      & > div {
        max-width: 100%;

        img {
          max-width: 100%;
        }

        & > p {
          font-size: 29px;
        }

        & > a {
          margin-bottom: 30px;
        }
      }
    }
  }
`
