import React from 'react'
import PropTypes from 'prop-types'
import Modal from '@/constants/Modal'
import * as emailjs from 'emailjs-com'
import { TYPES } from '@/constants/mocks/modalTypes'
import BriefingSite from '../BriefingSite'
import BriefingHotsite from '../BriefingHotsite'
import {
  FormTemplate
} from './styles'
import BriefingCasamento from '../BriefingCasamento'
import BriefingConvite from '../BriefingConvite'
import BriefingRetro from '../BriefingRetro'

const OPTIONS = {
  serviceID: 'website_gmail',
  templateID: 'briefing',
  userID: 'user_dB12ZSA3yPLQplccbAVT9',
}

const ModalTypes = (props) => {
  const {
    onClose,
    types,
    sendCallback,
    setLoading,
  } = props

  const handleClose = () => {
    onClose()
  }

  const handleSubmit = (form) => {
    setLoading()
    emailjs.send(
      OPTIONS.serviceID,
      OPTIONS.templateID,
      form,
      OPTIONS.userID
    )
      .then(() => {
         sendCallback(true)
      }, () => {
         sendCallback(false)
      })
  }

  const renderModalTypes = () => {
    if (types === TYPES.site) {
      return (
        <FormTemplate>
          <h3>Briefing para Sites</h3>
          <BriefingSite
            onSubmit={handleSubmit}
          />
        </FormTemplate>
      )
    }

    if (types === TYPES.hotsite) {
      return (
        <FormTemplate>
          <h3>Briefing para Hotsite</h3>
          <BriefingHotsite
            onSubmit={handleSubmit}
          />
        </FormTemplate>
      )
    }

    if (types === TYPES.casamento) {
      return (
        <FormTemplate>
          <h3>Briefing para Site de Casamento</h3>
          <BriefingCasamento
            onSubmit={handleSubmit}
          />
        </FormTemplate>
      )
    }

    if (types === TYPES.convite) {
      return (
        <FormTemplate>
          <h3>Briefing para Convite Virtual</h3>
          <BriefingConvite
            onSubmit={handleSubmit}
          />
        </FormTemplate>
      )
    }

    if (types === TYPES.retrospectiva) {
      return (
        <FormTemplate>
          <h3>Briefing para Retrospectiva</h3>
          <BriefingRetro
            onSubmit={handleSubmit}
          />
        </FormTemplate>
      )
    }

    if (types === TYPES.success) {
      return (
        <FormTemplate>
          <h2>Mensagem enviada com sucesso!</h2>
          <div className='alignC'>
            <a className='button success' onClick={handleClose}>Ok</a>
          </div>
        </FormTemplate>
      )
    }

    if (types === TYPES.fail) {
      return (
        <FormTemplate>
          <h2>Não foi possível enviar sua mensagem, por favor, tente novamente.</h2>
          <div className='alignC'>
            <a className='button warning' onClick={handleClose}>Ok</a>
          </div>
        </FormTemplate>
      )
    }

    if (types === TYPES.loading) {
      return (
        <FormTemplate>
          <h2>Carergando envio...</h2>
          <i className="fas fa-spinner" />
        </FormTemplate>
      )
    }
  }

  return (
    <Modal
      closeModal={handleClose}
      size='md'
    >
      {renderModalTypes()}
    </Modal>
  )
}

ModalTypes.propTypes = {
  onClose: PropTypes.func.isRequired,
  types: PropTypes.string.isRequired,
  setLoading: PropTypes.func.isRequired,
  sendCallback: PropTypes.func.isRequired,
}

export default ModalTypes
