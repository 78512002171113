import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Header from '@/components/views/Header'
import Home from '@/components/views/Home'
import Portfolio from '@/components/views/Portfolio'
import Service from '@/components/views/Service'
import Blog from '@/components/views/Blog'
import Contact from '@/components/views/Contact'
import Photos from '@/components/views/Photos'

const INNER_WIDTH_PARAM = 760

const DefaultRoute = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= INNER_WIDTH_PARAM)

  useEffect(() => {
    window.addEventListener('resize', () => {
      const widthW = window.innerWidth

      if (widthW <= INNER_WIDTH_PARAM) {
        return setIsMobile(true)
      }

      return setIsMobile(false)
    })
  }, [])

  return (
    <Router>
      <Header isMobile={isMobile} />

      <Routes>
        <Route path="/" element={<Home isMobile={isMobile} />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/services" element={<Service isMobile={isMobile} />} />
        <Route path="/blog" element={<Blog isMobile={isMobile} />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/photos" element={<Photos isMobile={isMobile} />} />
      </Routes>
    </Router>
  )
}

export default DefaultRoute


