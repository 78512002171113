import styled from '@emotion/styled'

export const StyledHeader = styled.header`
  width: 100%;
  height: 48px;
  position: fixed;
  top: 0;
  padding: 0 10px;
  left: 0;
  z-index: 99;
  transition: all 0.4s ease;
  background: rgba(255, 255, 255, 0.3);

  &:hover {
    background-color: rgba(123, 123, 123, 0.88);
  }

  &.home {
    background: rgba(0,0,0,.2);

    &:hover {
      background-color: rgba(189, 199, 184, 0.88);
    }
  }

  &.photos {
    background: #444444;

    h1 {
      a {
        color: #111;
      }
    }
  }

  h1 {
    float: left;
    font-family: Industrial;
    line-height: 48px;
    height: 48px;
    font-size: 26px;
    cursor: pointer;
    z-index: 101;
    position: relative;
    display: inline-block;

    a {
      color: #414344;

      &:hover {
        color: var(--color-primary);
      }
    }

    img {
      width:87px;
      height:117px;
      display:block;
      float:left;
      margin-right: 10px;
      margin-top: 5px;
      width: 38px;
      height: 54px;
    }
  }

  @media (min-width: 760px) {
    padding-right: 0;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
  }

  @media (min-width: 1020px) {
    height: 70px;
    padding: 0 30px;

    h1 {
      font-size: 41px;
      line-height: 60px;

      img {
        width: auto;
        height: 83px;
      }
    }
  }

  @media (min-width: 1280px) {
    h1 {
      line-height: 53px;
      height: 70px;
      font-size: 54px;
    }
  }

  @media (min-width: 1510px) {
    height: 80px;
    padding: 0 50px;

    h1 {
      font-size: 70px;
      line-height: 59px;

      img {
        height: 95px;
      }
    }
  }
`

export const StyledButtonMobile = styled.button`
  color: #3e3e3e;
  position: relative;
  display: block;
  float: right;
  width: 48px;
  height: 48px;
  background-color: transparent;
  font-size: 43px;
  border: none;

  &.photos {
    color: #111;
  }
`
