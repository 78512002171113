import React from 'react'
import PropTypes from 'prop-types'
import { menuHomeList } from '@/constants/mocks/homeContent'
import ComoFaz from '@/components/fragments/ComoFaz'
import MenuHome from '@/components/fragments/MenuHome'
import {
  SectionHome,
  Bloco1,
  Bloco2,
  Bloco3,
  Bloco4,
} from './styles'
import Footer from '../Footer'

const ITEM = {
  _1st: 0,
  _2nd: 1,
  _3rd: 2,
  _4th: 3,
}

const Home = (props) => {
  const {
    isMobile
  } = props

  return (
    <SectionHome>
      {!isMobile && (
        <MenuHome
          list={menuHomeList}
        />
      )}
      <Bloco1 id="presentation">
        {isMobile && (
          <p className='text'>{menuHomeList[ITEM._1st].description}</p>
        )}
      </Bloco1>

      <Bloco2 id="motive">
        {isMobile && (
          <p className="text">{menuHomeList[ITEM._2nd].description}</p>
        )}
        <div className="boxInfo">
          <p className="top">segundo as pesquisas...</p>
          <div className="bottom">
            <div className='group'>
              <div className="info">
                <p>O mundo já tem</p>
                <p>4,7 bilhões</p>
                <p>de pessoas conectadas à internet!</p>
              </div>
              <p className="meio">+</p>
              <div className="info second">
                <p>O Brasil atinge</p>
                <p>152 milhões</p>
                <p>de pessoas conectadas à internet!</p>
              </div>
            </div>
            <div className="conteudo">
              <p>Estes dados são de 2021, e mesmo que as informaçõe acima não digam nada, basta lembrar que la fora, na rua, cada pessoa está fixamente olhando para seus smartphones. O numero de pessoas trabalhando em frente a um computador, tablet, aumenta consideravelmente a cada ano, se não trabalhando, usa-o quando chega em casa, isso sem contar televisão com acesso a internet, computador de bordo dentro de novos carros, smartwatches e tantos outros gadgets.<br />
              Ufa... sim, você precisa de um site. Seja ele para divulgação do seu produto, do seu serviço, ou mesmo para coisas simples como um blog de informação cotidiana.
              </p>
            </div>
          </div>
        </div>
      </Bloco2>

      <Bloco3 id="how-to">
        {isMobile && (
          <p className="text">{menuHomeList[ITEM._3rd].description}</p>
        )}
        <ComoFaz />
      </Bloco3>

      <Bloco4 id="what-else">
        {isMobile && (
          <p className="text">{menuHomeList[ITEM._4th].description}</p>
        )}
        <div>
          <div>
            <img alt="" src="/imgs/Temp/convite.png" className="imgHome4" />
            <p>Além de sites institucionais, posso te ajudar em outra tipos de serviços, como retrospectiva de casamento, aniversário ou viagens, convite personalizado virtual. projetos para você ou sua empresa, controle de orçamentos, controle de estoque entre outros.
            </p>
            <a href="/services">
              QUERO VER MAIS SOBRE ISSO
              <i className="fa fa-angle-double-right" />
            </a>
          </div>
        </div>
      </Bloco4>

      <Footer />
    </SectionHome>
  )
}

Home.propTypes = {
  isMobile: PropTypes.bool.isRequired,
}

export default Home
