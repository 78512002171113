import styled from '@emotion/styled'

export const StyledMenu = styled.nav`
  @keyframes showMenu {
    from {
      top: 0;
      opacity: 0;
    }
    to {
      top: 100%;
      opacity: 1;
    }
  }

  @media (max-width: 760px) {
    width: 100%;
    background-color: #333;
    position: absolute;
    top: 100%;
    right: 0;
    animation: showMenu linear .2s;

    ul {
      padding: 20px 0;

      li {
        a {
          font-size: 20px;
          color: #444;
          font-weight: 600;
          padding: 15px 30px;
          display: block;
          text-transform: uppercase;
          margin: 0 auto;
          
          display: block;
          color: var(--color-light);
          border-bottom: 3px solid transparent;

          i {
            float: right;
          }

          &:hover, &.active {
            border-color: var(--color-primary);
            background: rgba(255,255,255,0.4);

            i {
              color: var(--color-primary);
            }
          }
        }
      }
    }

    @media (min-width:480px) {
      width: 480px;
    }
  }

  @media (min-width: 760px) {
    width: calc(100% - 240px);
    display: inline-block;
    text-align: right;

    ul {
      li {
        display: inline-block;
        vertical-align: top;
        position: relative;
        text-align: center;

        a {
          font-size: 14px;
          color: var(--color-bg-dark);
          font-weight: 800;
          padding: 0 11px;
          display: block;
          text-transform: uppercase;
          margin: 0 auto;
          height: 48px;
          line-height: 48px;
          display: block;
          border-bottom: 3px solid transparent;

          span {
            position: relative;
            z-index: 10;
          }

          i {
            position: absolute;
            opacity: .1;
            font-size: 30px;
            left: calc(50% - 15px);
            top: calc(50% - 15px);
            transition: all .01s linear;
          }

          &:hover, &.active {
            border-color: var(--color-primary);
            background: rgba(255,255,255,0.4);

            i {
              opacity: .25;
              transition: all .01s linear;
            }
          }

          &.active {
            i {
              color: var(--color-primary);
              opacity: .8;
            }
          }
        }
      }
  }

  @media (min-width: 1020px) {
    width: calc(100% - 360px);

    ul {
      li {
        a{
          height: 70px;
          line-height: 70px;
          padding: 0 15px;
        }
      }
    }
  }

  @media (min-width: 1280px) {
    width: calc(100% - 452px);

    ul {
      li {
        a{
          font-size: 21px;
          font-weight: 600;
        }
      }
    }
  }

  @media (min-width: 1510px) {
        width: calc(100% - 575px);

    ul {
      li {
        a {
          font-size: 24px;
          line-height: 80px;
          height: 80px;

          i {
            font-size: 50px;
            left: calc(50% - 25px);
            top: calc(50% - 25px);
          }
        }
      }
    }
  }
`
