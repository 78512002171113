export const menuHomeList = [ {
  description: 'Olá! Sou Front End Programmer há mais de 10 anos:) - Atuando em criação de sites institucionais e hotsites além de grandes projetos junto às empresas Caffé Digital e Leroy Merlin.',
  id: '1',
  link: 'presentation',
  title: 'QUEM SOU EU?',
}, {
  description: 'Se eu fosse listar todos os motivos... Mas você está aqui e já deve saber o porque, porém o principal é: Quem é você aqui na internet? Tem um mundo de pessoas online procurando o que você tem a oferecer!',
  id: '2',
  link: 'motive',
  title: 'PORQUE TER UM SITE?',
}, {
  description: 'Utilizando linguagem de programação atualizada, como ReactJS, JavaScript, HTML5, CSS3, Firebase, NodeJS. Vai manter seu site leve e rápido, ao mesmo tempo 100% responsivo, ajustado para telas grandes de computadores, médias como tablet e pequenas como smartphones',
  id: '3',
  link: 'how-to',
  title: 'E COMO FAZ?',
}, {
  description: 'MAIS?!? Porque não? Veja aí',
  id: '4',
  link: 'what-else',
  title: 'O QUE MAIS?',
} ]

export const comoFaz = [ {
  description: '<p>O React é uma biblioteca JavaScript de código aberto com foco em criar interfaces de usuário (frontend) em páginas web. É mantido pelo Facebook, Instagram, outras empresas e uma comunidade de desenvolvedores individuais. É utilizado nos sites da Netflix, Imgur, Feedly, Airbnb, SeatGeek, HelloSign, Walmart e outros. (wikipedia)</p>',
  img: 'react-logo.png',
  name: 'reactjs',
}, {
  description: '<p>JavaScript é uma linguagem de programação criada em 1995. Originalmente projetada para rodar no Netscape Navigator, ela tinha o propósito de oferecer aos desenvolvedores formas de tornar determinados processos de páginas web mais dinâmicos, tornando seu uso mais agradável. Um ano depois de seu lançamento, a Microsoft portou a linguagem para seu navegador, o que ajudou a consolidar a linguagem e torná-la uma das tecnologias mais importantes e utilizadas na internet.</p><p>Junto com o Javascript tem o jQuery que é um framework. Um framework, de forma bem resumida, é uma coleção de funções e métodos prontos para serem utilizados, amplamente testados e que devem ser usados de forma pré-definida para tudo correr bem.</p>',
  img: 'javascript.png',
  name: 'javascript',
}, {
  description: '<p>É a mais recente evolução do padrão que define o HTML. O termo representa dois diferentes conceitos: É uma nova versão da linguagem HTML, com novos elementos, atributos, e comportamentos e um conjunto maior de tecnologias que permite o desenvolvimento de aplicações e web sites mais diversos e poderosos.<br />               Se está difícil de enteder, não esquenta, é basicamente uma modernização no modo de você, usuário final, sentir-se mais confortável ao navegar por um site.</p>',
  img: 'html5.png',
  name: 'html',
}, {
  description: '<p>É a mais nova versão das famosas Cascading Style Sheets (ou simplesmente CSS), onde se define estilos para páginas web com efeitos de transição, imagem, e outros, que dão um estilo novo às páginas Web 2.0 em todos os aspectos de design do layout.</p><p>De forma bem grotesca, se o HTML fosse uma prateleira com produtos, o CSS seria o resultado do trabalho de um vitrinista que estrutura os produtos de forma ordenada e inclui decorações.</p>',
  img: 'css3.png',
  name: 'css3',
} ]

