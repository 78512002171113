import React, { useState } from 'react'
import PropTypes from 'prop-types'

const BriefingConvite = (props) => {
  const {
    onSubmit,
  } = props

  const [form, setForm] = useState({})

  const onChangeInputValue = (e) => {
    const { value, name} = e.target

    setForm({
      ...form,
      [name]: value,
    })
  }

  const setupComponent = () => (`
    Qual a data do evento?<br> 
    ${form.eventDate || 'N/D'}<br><br>
    Qual a finalidade (casamento, aniversário, chá bar, noivado, etc) do convite? <br>
    ${form.eventName || 'N/D'}<br><br>
    Qual a forma de entrega do convite virtual (email, whatsapp, facebook, via site) aos seus convidados? <br>
    ${form.inviteDelivery || 'N/D'}<br><br>
    Possui alguma arte do evento pronta para usar como base na criação do convite? <br>
    ${form.artInvite || 'N/D'}<br><br>
    Viu algum convite, seja ele impresso ou virtual no qual queira usar como exemplo para o seu? <br>
    ${form.preInvite || 'N/D'}<br><br>
    Qual o número de convites necessários? <br>
    ${form.inviteQuantity || 'N/D'}
  `)

  const submitForm = (e) => {
    e.preventDefault()
    const content = setupComponent()
    const formContent = {
      briefing_type: 'Convite',
      from_name: form.Name,
      reply_to: form.Email,
      briefing_contents: content,
    }

    onSubmit(formContent)
  }

  return (
    <form>
      <label className='label'>
        <span >Nome <b>*</b></span>
        <input
          name='Name'
          type='text'
          className='input'
          onChange={onChangeInputValue}
        />
      </label>

      <label className='label'>
        <span>E-mail <b>*</b></span>
        <input
          name='Email'
          type='email'
          className='input'
          onChange={onChangeInputValue}
        />
      </label>

      <label className='label'>
        <span>Qual a data do evento?</span>
        <input
          name='eventDate'
          type='date'
          className='input'
          onChange={onChangeInputValue}
        />
      </label>

      <label className='label'>
        <span>Qual a finalidade (casamento, aniversário, chá bar, noivado, etc) </span>do convite?
        <input
          name='eventName'
          type='text'
          className='input'
          placeholder='ex. chá bar'
          onChange={onChangeInputValue}
        />
      </label>

      <label className='label'>
        <span>Qual a forma de entrega do convite virtual (email, whatsapp, </span>facebook, via site) aos seus convidados?
        <input
          name='inviteDelivery'
          type='text'
          className='input'
          onChange={onChangeInputValue}
        />
      </label>

      <div className='radio label'>
        <span>Possui alguma arte do evento pronta para usar como base na criação do convite?</span>
        <label className='label-radio'>
          <input
            name='artInvite'
            value='Sim'
            type='radio'
            onChange={onChangeInputValue}
          />
          Sim
        </label>
        <label className='label-radio'>
          <input
            name='artInvite'
            value='Não'
            type='radio'
            onChange={onChangeInputValue}
          />
          Não
        </label>
      </div>

      <div className='radio label'>
        <span>Viu algum convite, seja ele impresso ou virtual no qual queira usar como exemplo para o seu?</span>
        <label className='label-radio'>
          <input
            name='preInvite'
            value='Sim'
            type='radio'
            onChange={onChangeInputValue}
          />
          Sim
        </label>
        <label className='label-radio'>
          <input
            name='preInvite'
            value='Não'
            type='radio'
            onChange={onChangeInputValue}
          />
          Não
        </label>
      </div>

      <label className='label'>
        <span>Qual o número de convites necessários?</span>
        <input
          name='inviteQuantity'
          type='number'
          className='input'
          onChange={onChangeInputValue}
        />
      </label>

      <button className='button' onClick={submitForm}>
        Enviar
      </button>
    </form>
  )
}

BriefingConvite.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default BriefingConvite
