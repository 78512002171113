import React, { useState } from 'react'
import Recaptcha from 'react-recaptcha'
import * as emailjs from 'emailjs-com'
import ModalTypes from './fragments/ModalTypes'
import { TYPES } from '@/constants/mocks/modalTypes'
import { StyledGrid } from './styles'
import BreadCrumb from '@/components/fragments/BreadCrumb'
import Footer from '../Footer'

const Contact = () => {
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState(null)
  const [form, setForm] = useState({})

  const onChangeInputValue = (e) => {
    const {name, value} = e.target
    setForm({
      ...form,
      [name]: value,
    })
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const handleCallback = (bool) => {
    setModalType(bool ? TYPES.success : TYPES.fail)
  }

  const handleLoading = () => {
    setModalType(TYPES.loading)
  }

  const renderModal = () => (
    <ModalTypes
      onClose={closeModal}
      types={modalType}
      sendCallback={handleCallback}
      setLoading={handleLoading}
    />
  )

  const onClickBriefing = (e) => {
    e.preventDefault()
    setShowModal(true)
    setModalType(e.target.name)
  }

  const submitForm = (e) => {
    e.preventDefault()

    const {formName, formEmail, formPhone, formMsg} = form
    const formContent = {
      to_name: 'Ivan Oliveira',
      from_name: formName,
      reply_to: formEmail,
      phone_number: formPhone,
      message_html: formMsg
    }

    emailjs.send('website_gmail', 'template_rGdA1EqN', formContent, 'user_dB12ZSA3yPLQplccbAVT9')
      .then(() => {
         this.onSuccessFormSubmit()
      }, () => {
         this.onFailFormRequest()
      })
  }


  return (
    <StyledGrid>
      {showModal && renderModal()}
      <BreadCrumb
        title='Contato'
      />
      <section>
        <div className='esqCont left'>
          <p className='titulo'>Vamos de briefing?</p>
          <div className='baixo'>
            <p className='texto'>Nem todos conseguem descrever exatamente o que precisa, então criei algumas perguntas específicas para facilitar a descrição da sua necessidade. Responder às questões dos briefings abaixo, não é obrigatório, caso saiba exatamente o que quer, utilize o formulário de contato.</p>
            <p className='texto'>Das questões do briefing, caso não saiba o que significa, pode deixar em branco, as questões também não são obrigatórias.</p>
            <p className='subtitulo'>E então? Qual é o seu interesse?</p>

            <ul>
              <li>
                <a onClick={onClickBriefing} name={TYPES.site} className='site'>
                  <i className='fa fa-home' />
                  Criação de Site
                </a>
              </li>
              <li>
                <a onClick={onClickBriefing} name={TYPES.hotsite} className='hotsite'>
                  <i className='fa fa-fire' />
                  Criação de Hotsite
                </a>
              </li>
              <li>
                <a onClick={onClickBriefing} name={TYPES.casamento} className='casamento'>
                  <i className='fa fa-male' />
                  <i className='fa fa-female' />
                  Site Casamento
                </a>
              </li>
              <li>
                <a onClick={onClickBriefing} name={TYPES.convite} className='convite'>
                  <i className='fa fa-desktop' />
                  Convite Virtual
                </a>
              </li>
              <li>
                <a onClick={onClickBriefing} name={TYPES.retrospectiva} className='retro'>
                  <i className='fa fa-film' />
                  Retrospectiva
                </a>
              </li>
            </ul>
            <span className='clear'></span>
            <p className='subtitulo'>Telefone e E-mail</p>
            <a href='https://wa.me/5511983082169'>
              <i className='fab fa-whatsapp' />
              WhatsApp
            </a>
            <a href='tel:+5511983082169'>
              <i className='fa fa-mobile' />
              (11) 98308-2169
            </a>
            <a href='mailto:admin@ivanoliveira.com.br'>
              <i className='fa fa-envelope' />
              admin@ivanoliveira.com.br
            </a>
          </div>
        </div>  
        <div className='dirCont right'>
          <p className='titulo'>Envie uma mensagem rapidamente para mim!</p>
          <form
            id='form'
            action=''
            method='post'
            className='clear'
            onSubmit={submitForm}>
            <input
              name='formName'
              type='text'
              id='Nome'
              placeholder='Nome'
              required
              onChange={onChangeInputValue}
            />
            <input
              name='formEmail'
              type='text'
              id='email'
              placeholder='E-mail (ex. meunome@meudominio.com)'
              pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'
              required
              onChange={onChangeInputValue}
            />
            <input
              name='formPhone'
              type='number'
              id='fone'
              placeholder='Telefone'
              required
              onChange={onChangeInputValue}
            />
            <textarea
              name='formMsg'
              id='mensagem'
              placeholder='Digite aqui a sua mensagem'
              required
              onChange={onChangeInputValue}
            ></textarea>
            <div className='Captcha left'>
              <Recaptcha
                sitekey='6LeZUiQTAAAAALDdYctn-g9NnPdWRs7IIc8lBEMb'
                render='explicit'
                theme='dark'
              />
            </div>
            <button type='submit' className='botao' name='Submit'>
              Enviar
            </button>
          </form>
        </div>
      </section>

      <Footer />
    </StyledGrid>
  )
}

export default Contact
