import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Menu from '../Menu'
import Logo from '@/imgs/logo.png'
import {
  StyledHeader,
  StyledButtonMobile,
} from './styles'
import { Link } from 'react-router-dom'

const Header = (props) => {
  const {
    isMobile
  } = props

  const [headerClass, setHeaderClass] = useState('home')
  const [openedMenu, setOpenedMenu] = useState(false)

  useEffect(() => {
    const path = window.location.pathname
    if (path === '/') return setHeaderClass('home')
    if (path === '/photos') return setHeaderClass('photos')
    setHeaderClass('')
  }, [])

  const handleNavClick = (url) => {
    let className = ''
    if (url === '/') {
      className = 'home'
    }
    if (url === '/photos') {
      className = 'photos'
    }
    setOpenedMenu(false)
    setHeaderClass(className)
  }

  return (
    <StyledHeader className={headerClass}>
      <h1 onClick={() => setHeaderClass('home')}>
        <Link to="/">
          <img src={Logo} alt="Logo Ivan Oliveira" />
          Ivan Oliveira
        </Link>
      </h1>
      {isMobile ? (
        <>
          <StyledButtonMobile
            className={`${headerClass} ${openedMenu ? 'active' : ''}`}
            onClick={() => setOpenedMenu(!openedMenu)}
          >
            <i className="fas fa-bars" />
          </StyledButtonMobile>
          {openedMenu && (<Menu
            onNavClick={handleNavClick}
            isOpened
          />)}
        </>
      ) : (
        <Menu
          onNavClick={handleNavClick}
        />
      )}
    </StyledHeader>
  )
}

Header.propTypes = {
  isMobile: PropTypes.bool.isRequired,
}

export default Header
