import styled from '@emotion/styled'

export const StyledGrid = styled.div`
  padding-top: 48px;

  @media (min-width: 1020px) {
    padding-top: 70px;
  }

  @media (min-width: 1510px) {
    padding-top: 80px;
  }
`

export const StyledContainer = styled.section`
  background: var(--color-gray-light);
  margin-bottom: 30px;
  padding: 15px;

  article {
    .tituloInterno {
      font-size: 25px;
      font-family: 'Alternate';
      color: var(--color-bg-dark2);
      border: 1px solid;
      padding: 10px 20px;
      margin-bottom: 20px;
      text-align: center;

      i {
        margin-right: 5px;
        color: var(--color-primary);
        background-color: var(--color-bg-dark2);
        padding: 4px;
      }
    }

    .contentText {
      p {
        font-size: 15px;
        line-height: 25px;
        margin-bottom: 20px;
      }

      a {
        margin-right: 20px;
        color: var(--color-bg-dark2);
        font-weight: bold;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      ul {
        li {
          margin-bottom: 15px;
        }
      }

      iframe{
        margin-bottom: 30px;
        width:560px;
        height:315px;
      }
    }
  }

  @media (min-width: 760px) {
    article {
      width: calc(100% - 320px);
      margin-left: 20px;
      display: inline-block;
    }
  }

  @media (min-width: 1020px) {
    width: calc(100% - 100px);
    padding: 30px;
    margin: 0 auto 30px;

    article {
      width: calc(100% - 330px);
      margin-left: 30px;
    }
  }

  @media (min-width: 1510px) {
    width: 70%;
  }
`
