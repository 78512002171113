import React, { useState } from 'react'
import { comoFaz } from '@/constants/mocks/homeContent'
import {
  StyledContainer,
} from './styles'

const ComoFaz = () => {
  const [currentTab, setCurrentTab] = useState(0)

  const clickMenuItem = (i) => {
    setCurrentTab(i)
  }

  const renderMenu = (item, i) => (
    <button
      key={i}
      className={i === currentTab ? 'active' : ''}
      onClick={() => clickMenuItem(i)}
    >
      {item.name}
    </button>
  )

  const renderContent = (item, i) => {
    if (i !== currentTab) return

    return (
      <li key={i}>
        <img alt={item.name} src={`/imgs/Temp/${item.img}`} />
        <div dangerouslySetInnerHTML={{__html: item.description}} />
      </li>
    )
  }

  return (
    <StyledContainer>
      <p className="top">SE QUISER ENTENDER UM POUQUINHO MAIS DELES...</p>
      <div className="bottom clear">
        <nav>
          {comoFaz.map((item, i) => renderMenu(item, i))}
        </nav>
        <ul>
          {comoFaz.map((item, i) => renderContent(item, i))}
        </ul>

      </div>
    </StyledContainer>
  )
}

export default ComoFaz
