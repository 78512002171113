import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { flickrRequest } from '@/api/request-flickr'
import Logo from '@/imgs/camera.png'
import { CustomAside } from './styles'

const DEFAULTS = {
  list: 'Animals',
  orders: 'getAlbumList',
  panorama: 'Panorama',
}

const AsidePhotos = (props) => {
  const {
    isMobile,
    panoramaPage,
    getAlbumCode,
  } = props

  const [menuNames, setMenuNames] = useState([])
  const [selectedList, setSelectedList] = useState(DEFAULTS.list)
  const [menuMobile, setMenuMobile] = useState(false)

  useEffect(() => {
    flickrRequest({order: DEFAULTS.orders})
      .then( resp => {
        setMenuNames(resp.data.photosets.photoset)
      })
  }, [])

  const configParnoramaPage = (page) => {
    let pageItem = false
    if (page === DEFAULTS.panorama) {
      pageItem = true
    }

    return panoramaPage(pageItem)
  }

  const onMenuClick = (e) => {
    const { text, name } = e.target
    e.preventDefault()

    if (text === selectedList) {
      return
    }

    window.scroll(0, 0)

    configParnoramaPage(text)
    getAlbumCode(name)
    setSelectedList(text)
    setMenuMobile(false)
  }

  const renderList = (value, index) => (
    <li key={index} className={selectedList === value.title._content ? 'active' : ''}>
      <a onClick={onMenuClick} name={value.id}>{value.title._content}</a>
      {isMobile ? (
        <i className="fas fa-eye" />
      ) : (
        <i className="fas fa-chevron-right" />
      )}
    </li>
  )


  return (
    <CustomAside className={menuMobile ? 'active' : ''}>
      <img className='camera' alt='camera' src={Logo} />
      <ul>
        {isMobile && (
          <li className='active'>
            <a className='active' onClick={() => setMenuMobile(!menuMobile)}>
              <span>Está na seção:</span> {selectedList}
            </a>
            <i className="fas fa-eye" />
          </li>
        )}
        {menuNames.map((value, index) => renderList(value, index))}
      </ul>
    </CustomAside>
  )
}

AsidePhotos.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  getAlbumCode: PropTypes.func.isRequired,
  panoramaPage: PropTypes.func.isRequired,
}

export default AsidePhotos
