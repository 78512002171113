import React from 'react'
import PropTypes from 'prop-types'
import { StyledBar } from './styles'

const BreadCrumb = (props) => {
  const {
    description,
    title,
  } = props

  return (
    <StyledBar>
      <div>
        <p>
          {title} {Boolean(description) && (<span>- {description}</span>)}
        </p>
      </div>
    </StyledBar>
  )
}

BreadCrumb.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
}

BreadCrumb.defaultProps = {
  description: null,
}

export default BreadCrumb
