import styled from '@emotion/styled'

export const CustomAside = styled.nav`
  padding: 0 0 5px;
  width: 100%;
  position: relative;

  img {
    &.camera {
      display: inline-block;
      width: 40px;
      margin: 0 0 0 20px;
      vertical-align: top;
    }
  }

  ul {
    width: calc(100% - 60px);
    display: inline-block;
    position: relative;
    padding: 0 15px;

    li {
      opacity: 0;
      z-index: -1;
      height: 0;
      overflow: hidden;
      position: relative;

      &:first-of-type {
        opacity: 1;
        z-index: 1;
        height: auto;
      }

    i {
      font-size: 23px;
      opacity: 0;
      position: absolute;
      right: 10px;
      top: calc(50% - 12.5px);
      transition: all .3s ease-in-out;
    }

    a {
      background-color: var(--color-bg-dark2);
      color: var(--color-secondary);
      cursor: pointer;
      display: block;
      font-weight: bold;
      margin-bottom: 5px;
      padding: 0 15px;
      position: relative;
      transition: all .1s ease-in-out;
      line-height: 34px;
      font-size: 13px;

        span {
          font-style: italic;
          font-size: 12px;
          display: inline-block;
          margin-right: 4px;
        }
      }

      &.active, &:hover {
        i {
          opacity: 1;
          color: var(--color-primary);
        }
      }
    }
  }

  &.active {
    ul {
      li {
        opacity: 1;
        z-index: 1;
        height: auto;
      }
    }
  }

  @media (min-width: 760px) {
    display: inline-block;
    width: 200px;
    vertical-align: top;
    border-right: .5px solid rgba(255, 255, 255, .5);

    img {
      &.camera {
        width: 70%;
        margin: 30px 0 30px 15%;
      }
    }

    ul {
      width: 100%;

      li {
        overflow: initial;
        opacity: 1;
        z-index: 1;
        height: auto;

        a {
          color: #111;
        }

        &.active, &:hover {
          left: 20px;

          i {
            right: -3px;
          }
        }
      }
    }
  }

  @media (min-width: 1020px) {
    width: 300px;
    padding-bottom: 100px;

    ul {
      li {
        a {
          font-size: 18px;
          padding: 12px 15px;
        }
      }
    }
  }
`
