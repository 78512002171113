import axios from 'axios'

export const flickrRequest = (props) => {
  let URL
  const key = '7edf590df6e11f9bece976527cc68050'
  const defaultURL = 'https://www.flickr.com/services/rest/?method=flickr.'
  const user = '183853090%40N02'
  const formatDefault = '&format=json&nojsoncallback=1'
  switch(props.order) {
    case 'getAlbum':
      URL = `${defaultURL}photosets.getPhotos&api_key=${key}&photoset_id=${props.code}&user_id=${user}${formatDefault}`
      break;
    case 'getPhotoInfo':
      URL = `${defaultURL}photos.getInfo&api_key=${key}&photo_id=${props.code}${formatDefault}`
      break;
    case 'getAlbumList':
      URL = `${defaultURL}photosets.getList&api_key=${key}&user_id=${user}${formatDefault}`
      break;
    default :
      return
  }

  return axios.get(URL)
}
