import styled from '@emotion/styled'

export const StyleButtonMobile = styled.button`
    color: var(--color-light);
    position: absolute;
    display: block;
    float: right;
    right: 10px;
    width: auto;
    font-family: AgencyFB;
    border: 1px solid var(--color-light);
    top: 58px;
    line-height: 21px;
    padding: 10px 20px;
    background-color: transparent;
    font-size: 16px;
`

export const StyledNav = styled.nav`
  width: 100%;
  margin-bottom: 30px;
  position: relative;
  z-index: 10;

  @keyframes ShowMenu {
    from {
      opacity: 0;
      top: -145px;
    }

    to {
      opacity: 1;
      top: -45px;
    }
  }

  & > ul {
    position: absolute;
    margin: 0px;
    background: var(--color-light);
    width: 100%;
    border: 2px solid var(--color-bg-dark2);
    border-top: none;
    top: -45px;
    display: block;
    padding: 10px;
    animation: ShowMenu .2s linear;

    li {
      display: block;
      text-transform: uppercase;
      border-bottom: 1px solid var(--color-bg-dark2);

      a {
        display: block;
        padding: 10px;
        font-size: 19px;
        color: var(--color-bg-dark2);
        line-height: 21px;
        margin: 10px 0;
        cursor: pointer;

        &.active, &:hover {
          background: var(--color-bg-dark2);
          color: var(--color-secondary);

          i {
            color: var(--color-primary);
          }
        }

        i {
          font-size: 12px;
          vertical-align: middle;
          margin-right: 5px;
        }
      }

      & > ul {
        &.submenu {
          margin-bottom: 10px;

          li {
            a {
              padding: 8px 10px 8px 30px;
              font-size: 16px;
              margin: 0;
            }

            border: none;
          }

        }
      }
    }
  }

  @media (min-width: 760px) {
    width: 300px;
    display: inline-block;
    vertical-align: top;

    & > ul {
      position: initial;
      margin: 0;
      background-color: transparent;
      border: 1px solid;

      li {
        border: none;

        a {
          margin: 0 0 1px;
        }

        ul.submenu {
          margin: 0;
        }
      }
    }
  }
`

