import React, { useState } from 'react'
import PropTypes from 'prop-types'

const BriefingRetro = (props) => {
  const {
    onSubmit,
  } = props

  const [form, setForm] = useState({})

  const onChangeInputValue = (e) => {
    const { value, name} = e.target

    setForm({
      ...form,
      [name]: value,
    })
  }

  const setupComponent = () => (`
    Qual a finalidade da retrospectiva?<br> 
    ${form.objective || 'N/D'}<br><br>
    O tempo convencional de um video é de 5 a 8 minutos. Mas isso dependerá do conteúdo. Sendo assim. Tem ideia de quanto tempo terá seu vídeo? <br>
    ${form.videoTime || 'N/D'}<br><br>
    Para um bom resultado, as imagens precisam ser de boa qualidade, e o mínimo recomendado é 1920px de largura, e somando todas as imagens e vídeos para a retrospectiva, o peso seria inviável para enviar por email. Sendo assim, você conseguiria enviar este conteúdo em ferramentas como Wetransfer, Dropbox, FTP, Google Drive, outros? <br>
    ${form.sendMedia || 'N/D'}<br><br>
    Qual o tipo de retrospectiva? <br>
    ${form.retroType || 'N/D'}
  `)

  const submitForm = (e) => {
    e.preventDefault()
    const content = setupComponent()
    const formContent = {
      briefing_type: 'Retrospectiva',
      from_name: form.Name,
      reply_to: form.Email,
      briefing_contents: content,
    }

    onSubmit(formContent)
  }

  return (
    <form>
      <label className='label'>
        <span >Nome <b>*</b></span>
        <input
          name='Name'
          type='text'
          className='input'
          onChange={onChangeInputValue}
        />
      </label>

      <label className='label'>
        <span>E-mail <b>*</b></span>
        <input
          name='Email'
          type='email'
          className='input'
          onChange={onChangeInputValue}
        />
      </label>

      <label className='label'>
        <span>Qual a finalidade da retrospectiva?</span>
        <input
          name='objective'
          type='text'
          className='input'
          onChange={onChangeInputValue}
        />
      </label>

      <label className='label'>
        <span>O tempo convencional de um video é de 5 a 8 minutos. Mas isso dependerá do conteúdo. Sendo assim. Tem ideia de quanto tempo terá seu vídeo?</span>
        <input
          name='videoTime'
          type='text'
          className='input'
          placeholder='ex. 5min'
          onChange={onChangeInputValue}
        />
      </label>

      <div className='radio label'>
        <span>Para um bom resultado, as imagens precisam ser de boa qualidade, e o mínimo recomendado é 1920px de largura, e somando todas as imagens e vídeos para a retrospectiva, o peso seria inviável para enviar por email. Sendo assim, você conseguiria enviar este conteúdo em ferramentas como Wetransfer, Dropbox, FTP, Google Drive, outros?</span>
        <label className='label-radio'>
          <input
            name='sendMedia'
            value='Sim'
            type='radio'
            onChange={onChangeInputValue}
          />
          Sim
        </label>
        <label className='label-radio'>
          <input
            name='sendMedia'
            value='Não'
            type='radio'
            onChange={onChangeInputValue}
          />
          Não
        </label>
      </div>

      <div className='radio label'>
        <span>Qual o tipo de retrospectiva?</span>
        <label className='label-radio'>
          <input
            name='retroType'
            value='Convencional, com musica, imagens e videos.'
            type='radio'
            onChange={onChangeInputValue}
          />
          Convencional, com musica, imagens e videos.
        </label>
        <label className='label-radio'>
          <input
            name='retroType'
            value='Narrativa, seja texto ou voz, além de videos, fotos, e músicas.'
            type='radio'
            onChange={onChangeInputValue}
          />
          Narrativa, seja texto ou voz, além de videos, fotos, e músicas.
        </label>
      </div>

      <button className='button' onClick={submitForm}>
        Enviar
      </button>
    </form>
  )
}

BriefingRetro.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default BriefingRetro
