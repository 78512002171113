import styled from '@emotion/styled'

export const StyledGrid = styled.div`
  padding-top: 48px;

  section {
    background-color: var(--color-gray-light);
    padding: 30px 10px;

    & > div {
      width: 100%;
      border: 1px solid #414344;
      margin-bottom: 30px;

      & > p {
        &.titulo {
          background: var(--color-bg-dark2);
          color: #cecece;
          font-family: 'Alternate';
          font-size: 23px;
          padding: 10px 20px;
        }
      }
    }

    .esqCont {
      .baixo {
        padding: 20px;

        p {
          &.texto {
            color: var(--color-bg-dark2);
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 11px;
          }

          &.subtitulo {
            border-bottom: 1px solid;
            color: #414344;
            font-family: 'Alternate';
            font-size: 18px;
            margin-bottom: 8px;
            margin-top: 17px;
            padding: 10px 0;
          }
        }

        & > ul {
          display: grid;
          grid-template-columns: repeat(2, calc(50% - 5px));
          grid-gap: 10px;

          li {
            margin-bottom: 5px;
            display: inline-block;
            border: 1px solid;

            a {
              display: inline-block;
              vertical-align: middle;
              font-size: 15px;
              padding: 10px;
              cursor: pointer;
              color: var(--color-bg-dark2);
              font-weight: 700;
              width: 100%;

              i {
                font-size: 18px;
                margin-right: 10px;

                &.fa-male {
                  margin-right: 0;
                }
              }

              &:hover {
                background: var(--color-bg-dark2);
                color: var(--color-secondary);
              }
            }

            &:nth-of-type(2n+2) {
              a {
                margin-right: 0;
              }
            }
          }
        }

        & > a {
          color: var(--color-bg-dark2);
          font-size: 13px;
          font-weight: bold;
          margin: 10px 10px 0 0;
          display: inline-block;
          padding: 5px 10px;
          border-radius: 5px;
          border: 1px solid var(--color-gray-light);

          i {
            font-size: 20px;
            margin-right: 5px;
            vertical-align: middle;
          }

          &:hover {
            color: var(--color-bg-dark2);
            background-color: var(--color-primary);
            border-color: var(--color-bg-dark2);
          }
        }
      }
    }

    .dirCont {
      form {
        padding: 20px;

        input, textarea {
          margin-bottom: 20px;
          border: 1px solid var(--color-bg-dark2);
          height: 51px;
          background-color: transparent;
          font-size: 16px;

          &::-webkit-input-placeholder {
            color: var(--color-bg-dark2);
          }
        }

        textarea {
          padding: 15px;
          height: 100px;
          font-family: 'Open Sans', sans-serif;
        }

        button {
          background: var(--color-bg-dark2);
          color: var(--color-secondary);
          border: 1px solid !important;
          width: calc(100% - 324px);
          float: right;
          height: 56px;
          font-weight: 800;

          &:hover {
            background: var(--color-primary);
            color: var(--color-bg-dark2);
          }
        }

        img {
          border: 1px solid var(--color-secondary);
          border-right: none;
          float: left;
          height: 50px;
          width: 180px;
        }

        .Captcha {
          float: left;
          width: 304px;
        }
      }
    }
  }

  @media (min-width: 760px) {
    section {
      width: calc(100% - 60px);
      margin: 0 auto 30px;
      padding: 20px;
      display: grid;
      grid-template-columns: repeat(2, calc(50% - 10px));
      grid-gap: 20px;

      & > div {
        &.dirCont {
          form {
            button {
              width: 100%;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }

  @media (min-width: 1020px) {
    padding-top: 70px;

    section {
      padding: 30px;
      grid-gap: 30px;
      grid-template-columns: repeat(2, calc(50% - 15px));

      & > div {
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: 1510px) {
    padding-top: 80px;

    section {
      width: 70%;
    }
  }
`
