export const socialMedia = [ {
  name: 'Linkedin',
  icon: 'fab fa-linkedin',
  url: 'https://br.linkedin.com/in/ivanoliveiranet'
}, {
  name: 'twitter',
  icon: 'fab fa-twitter-square',
  url: 'https://twitter.com/IvanOliveiraNet'
}, {
  name: 'instagram',
  icon: 'fab fa-instagram-square',
  url: 'https://instagram.com/ivanaugoli/'
}, {
  name: 'facebook',
  icon: 'fab fa-facebook-square',
  url: 'https://www.facebook.com/IvanOliveiraNet'
}, {
  name: 'youtube',
  icon: 'fab fa-youtube-square',
  url: 'https://www.youtube.com/c/IvanOliveirawebdesigner'
} ]
