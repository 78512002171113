import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { menuDefault } from '@/constants/mocks/menuLinks'
import { StyledMenu } from './styles'

const Menu = (props) => {
  const {
    onNavClick,
  } = props

  const handleClick = (url) => {
    onNavClick(url)
  }

  const renderItems = (item, i) => (
    <li key={i}>
      <NavLink
        to={item.url}
        title={item.title}
        activeclassname="active"
        onClick={() => handleClick(item.url)}
      >
        <span>
          {item.title}
        </span>
        <i className={item.icon} />
      </NavLink>
    </li>
  )

  return (
    <StyledMenu>
      <ul>
        {menuDefault.map((item, index) => renderItems(item, index))}
      </ul>
    </StyledMenu>
  )
}

Menu.propTypes = {
  onNavClick: PropTypes.func.isRequired,
}

export default Menu
