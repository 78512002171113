import React, { useState } from 'react'
import BreadCrumb from '@/components/fragments/BreadCrumb'
import { onlineSites, templates } from '@/constants/mocks/portfolioContent'
import Modal from '@/constants/Modal'
import ImageGallery from 'react-image-gallery'
import Footer from '../Footer'
import {
  StyledGrid,
  StyledBox,
} from './styles'

const URL = '/imgs/Temp/portfolio/'

const Portfolio = () => {
  const [showModal, setShowModal] = useState(false)
  const [imageShow, setImageShow] = useState(false)

  const setModalImage = (e, fotos) => {
    e.preventDefault()
    const foto = fotos.map((foto) => {
      const imageLink = {original: `${URL}${foto.original}`}
      return imageLink
    })

    setShowModal(true)
    setImageShow(foto)
  }

  const renderContent = (item, i) => {
    if (!item) return

    const imageBg = `url(${URL}${item.fotos[0].original})`
    return (
      <StyledBox key={i} style={{backgroundImage: imageBg}}>
        <div>
          <p>{item.name}</p>
          <a onClick={(e) => setModalImage(e, item.fotos)}>
            VER IMAGENS
            <i className="fa fa-images" />
          </a>
        </div>
      </StyledBox>
    )
  }

  const renderTemplates = (item, i) => {
    if (!item) return

    const imageBg = `url(${URL}${item.img})`
    return (
      <StyledBox key={i} style={{backgroundImage: imageBg}}>
        <div>
          <p>{item.legenda}</p>
          <a href={item.url} target="_blank" rel="noreferrer" >
            VER TEMA
            <i className="fa fa-sign-out" />
          </a>
        </div>
      </StyledBox>
    )
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const renderModal = () => (
    <Modal
      closeModal={closeModal}
      size='lg'
    >
      <ImageGallery
        items={imageShow}
        showThumbnails={false}
        infinite={false}
        showPlayButton={false}
      />
    </Modal>
  )

  return (
    <StyledGrid>
      {showModal && renderModal()}
      <BreadCrumb
        title='PORTFÓLIO'
        description='Veja abaixo alguns clientes meus...'
      />
      <article>
        <div>
          {onlineSites.map((item, i) => renderContent(item, i))}
        </div>

        <BreadCrumb
          title='E MAIS'
          description='Veja também outros templates...'
        />

        <div>
          {templates.map((item, i) => renderTemplates(item, i))}
        </div>
      </article>

      <Footer />
    </StyledGrid>
  )
}

export default Portfolio
