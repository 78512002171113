import styled from '@emotion/styled'

export const StyledBar = styled.nav`
  width: 100%;
  background: var(--color-bg-dark2);
  color: var(--color-secondary);
  font-size: 32px;
  font-family: 'Alternate';
  margin-bottom: 30px;
  padding: 15px;

  span {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    display: block;
  }

  @media (min-width: 760px) {
    padding-left: 58px;

    span {
      vertical-align: middle;
      display: inline-block;
    }
  }

  @media (min-width: 1020px) {
    padding-left: 101px;
  }

  @media (min-width: 1510px) {
    padding-left: 131px;
  }
`
