import styled from '@emotion/styled'

export const StyledNav = styled.nav`
  position: fixed;
  width: 300px;
  top: 200px;
  left: 20px;
  z-index: 200;

  ul {
    li {
      display: block;

      a {
        color: rgba(65, 67, 68, 0.3);
        font-size: 32px;
        font-family: 'Alternate';
        text-transform: uppercase;
        cursor: pointer;

        span {
          font-family: 'Industrial';
          font-size: 32px;
          width: 28px;
          display:block;
          float:left;
          line-height: 19px;
        }

        &.active, &:hover {
          color: var(--color-bg-dark2);
        }
      }

      & > div {
        height: 0;
        overflow: hidden;
        opacity: 0;
        font-size: 0;
        transition: all .2s ease-in-out;
        padding: 0;
        position: relative;

        p {
          font-family: 'AgencyFB';
          color: var(--color-bg-dark2);
        }
      }

      &.active {
        & > div {
          font-size: 22px;
          opacity: 1;
          padding: 10px 0;
          height:auto;
          transition: all .2s ease-in-out;
        }
      }
    }
  }

  @media (min-width: 1280px) {
    left: 50px;
    width: 400px;

    ul {
      li {
        a {
          font-size: 49px;

          span {
            width: 46px;
            font-size: 47px;
            line-height: 31px;
          }
        }

        &.active {
          & > div {
            font-size: 29px;
          }
        }
      }
    }
  }
`
