export const onlineSites = [ {
  fotos: [ {
    original: 'spartacus01.jpg',
  }, {
    original: 'spartacus02.jpg',
  }, {
    original: 'spartacus03.jpg',
  }, {
    original: 'spartacus04.jpg',
  }, {
    original: 'spartacus05.jpg',
  }, {
    original: 'spartacus06.jpg',
  }, {
    original: 'spartacus07.jpg',
  }, {
    original: 'spartacus08.jpg',
  }, {
    original: 'spartacus09.jpg',
  }, {
    original: 'spartacus10.jpg',
  }, {
    original: 'spartacus11.jpg',
  }, {
    original: 'spartacus12.jpg',
  }, {
    original: 'spartacus13.jpg',
  }, {
    original: 'spartacus14.jpg',
  }, {
    original: 'spartacus15.jpg',
  } ],
  name: 'Spartacus Brazil',
}, {
  fotos: [ {
    original: 'hit-bra01.jpg',
  }, {
    original: 'hit-bra02.jpg',
  }, {
    original: 'hit-bra03.jpg',
  }, {
    original: 'hit-bra04.jpg',
  }, {
    original: 'hit-bra05.jpg',
  }, {
    original: 'hit-bra06.jpg',
  }, {
    original: 'hit-bra07.jpg',
  } ],
  name: 'Hit Bra',
}, {
  fotos: [ {
    original: 'summit01.jpg',
  }, {
    original: 'summit02.jpg',
  }, {
    original: 'summit03.jpg',
  }, {
    original: 'summit04.jpg',
  }, {
    original: 'summit05.jpg',
  } ],
  name: 'Summit',
}, {
  fotos: [ {
    original: 'parmenes01.jpg',
  }, {
    original: 'parmenes02.jpg',
  }, {
    original: 'parmenes03.jpg',
  }, {
    original: 'parmenes04.jpg',
  }, {
    original: 'parmenes05.jpg',
  } ],
  name: 'Parmenes IT',
}, {
  fotos: [ {
    original: 'blindap01.jpg',
  }, {
    original: 'blindap02.jpg',
  }, {
    original: 'blindap03.jpg',
  }, {
    original: 'blindap04.jpg',
  }, {
    original: 'blindap05.jpg',
  } ],
  name: 'Blindap',
}, {
  fotos: [ {
    original: 'sustentah01.jpg',
  }, {
    original: 'sustentah02.jpg',
  }, {
    original: 'sustentah03.jpg',
  }, {
    original: 'sustentah04.jpg',
  }, {
    original: 'sustentah05.jpg',
  } ],
  name: 'Sustentah',
}, {
  fotos: [ {
    original: 'euphorias01.jpg',
  }, {
    original: 'euphorias02.jpg',
  }, {
    original: 'euphorias03.jpg',
  }, {
    original: 'euphorias04.jpg',
  }, {
    original: 'euphorias05.jpg',
  } ],
  name: 'Euphorias',
}, {
  fotos: [ {
    original: 'cbs01.jpg',
  }, {
    original: 'cbs02.jpg',
  }, {
    original: 'cbs03.jpg',
  }, {
    original: 'cbs04.jpg',
  }, {
    original: 'cbs05.jpg',
  } ],
  name: 'Criando Belo Sorriso',
}, {
  fotos: [ {
    original: 'joeivan01.jpg',
  }, {
    original: 'joeivan02.jpg',
  }, {
    original: 'joeivan03.jpg',
  } ],
  name: 'Site/Blog Casamento',
} ]

export const templates = [ {
    img: 'casamento.jpg',
    legenda: 'Site para Casamentos',
    url: 'http://casamento.ivanoliveira.com.br',
  }, {
    img: 'altaestudio.jpg',
    legenda: 'Site para Estudios',
    url: 'http://testes.ivanoliveira.com.br/AltaVista',
  }, {
    img: 'searchtattoo.jpg',
    legenda: 'Estudios de Tatuagem',
    url: 'http://testes.ivanoliveira.com.br/searchtattoo',
  }, {
    img: 'ohjoy.jpg',
    legenda: 'Para Eventos e Infantis',
    url: 'http://testes.ivanoliveira.com.br/OhJoy',
  }, {
    img: 'aqua.jpg',
    legenda: 'Site Esportivo',
    url: 'http://testes.ivanoliveira.com.br/bhyo',
  }, {
    img: 'planetarivm.jpg',
    legenda: 'Tema Politico',
    url: 'http://testes.ivanoliveira.com.br/PlanetArivm',
  }, {
    img: 'clinicavet.jpg',
    legenda: 'Veterinário - Animais',
    url: 'http://testes.ivanoliveira.com.br/CVRjafet',
  }, {
    img: 'magic.jpg',
    legenda: 'Dark - Magic',
    url: 'http://testes.ivanoliveira.com.br/IssaoImamura',
  } ]
