import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import MenuService from '@/components/fragments/MenuService'
import BreadCrumb from '@/components/fragments/BreadCrumb'
import Footer from '../Footer'
import { serviceList } from '@/constants/mocks/serviceMock'
import {
  StyledGrid,
  StyledContainer,
} from './styles'

const Service = (props) => {
  const {
    isMobile,
  } = props

  const [currentArticle, setCurrentArticle] = useState('')

  useEffect(() => {
    const url = window.location.href.split('#')
    setCurrentArticle(url[1] || 'sites-institucionais')
  }, [])

  const handleChange = (id) => {
    setCurrentArticle(id)
  }

  const renderArticle = (item, i) => {
    if (currentArticle !== item.id) return

    return (
      <article key={i}>
        <p className="tituloInterno">
          <i className={item.icon} />
          <span>{item.title}</span>
        </p>
        <div className="contentText">
          <p dangerouslySetInnerHTML={{__html: item.description}} />
        </div>
      </article>
    )
  }

  return (
    <StyledGrid>
      <BreadCrumb
        title='SERVIÇO'
        description={isMobile ? '' : 'em que posso ajudá-lo hoje?'}
      />
      <StyledContainer>
        <MenuService
          isMobile={isMobile}
          onChange={handleChange}
        />
        
        {serviceList.map((item, index) => renderArticle(item, index))}
      </StyledContainer>

      <Footer />
    </StyledGrid>
  )
}

Service.propTypes = {
  isMobile: PropTypes.bool.isRequired,
}

export default Service
