import styled from '@emotion/styled'

export const FooterStyled = styled.header`
  padding: 25px;
  background: var(--color-bg-dark2);
  color: var(--color-secondary);

  .esq {
    border-bottom: 1px solid var(--color-secondary);
    margin-bottom: 20px;

    p {
      font-style: italic;
      font-weight: 300;
      line-height: 26px;
      font-size: 18px;

      &:last-of-type {
        text-align: right;
        font-style: initial;
        font-size: 14px;
        margin-bottom: 5px;
      }
    }
  }

  .redesSociais {
    p {
      font-weight: 400;
      margin-bottom: 10px;
    }

    a {
      display: inline-block;
      margin-right: 14px;

      i {
        font-size: 32px;
      }

      &:hover {
        color: var(--color-primary);
      }
    }
  }

  @media (min-width: 600px) {
    .esq {
      width: 340px;
      display: inline-block;
      vertical-align: top;
      margin: 0;
      border: none;

      p:last-of-type {
        margin: 0;
      }
    }

    .redesSociais {
      width: calc(100% - 340px);
      text-align: right;
      display: inline-block;

      a:last-of-type {
        margin: 0;
      }
    }
  }
`
