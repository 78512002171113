import styled from '@emotion/styled'

export const FormTemplate = styled.div`
  width: 100%;

  @keyframes Loading {
    from {
      transform: rotate(0deg);
      color: var(--color-bg-dark2);
    }
    to {
      transform: rotate(360deg);
      color: var(--color-primary);
    }
  }

  h3 {
    background-color: var(--color-bg-dark2);
    color: var(--color-gray-light);
    padding: 15px 20px;
    margin: 20px 0;
  }

  form {
    label {
      &.label {
        display: block;
        margin-bottom: 20px;

        &-radio {
          display: inline-block;
          margin: 8px 20px 20px 0;
          font-weight: 700;
          font-size: 12px;
          text-transform: uppercase;

          & > input {
            margin: 0 5px 0 0;
            height: auto;
          }
        }

        & > input {
          margin-top: 5px;

          &::-webkit-input-placeholder {
            color: var(--color-bg-dark2);
          }
        }

        & > span {
          display: block;
        }
      }
    }

    & > div {
      &.label {
        & > span {
          display: block;
        }
      }
    }

  }

  i.fa-spinner {
    font-size: 70px;
    margin: 50px auto;
    display: block;
    width: 70px;
    animation: Loading infinite 1.5s linear;
  }
`
