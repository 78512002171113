export const menuDefault = [{
  url: '/',
  title: 'Quem?',
  icon: 'fas fa-home',
}, {
  url: '/portfolio',
  title: 'Portifólio',
  icon: 'far fa-images',
}, {
  url: '/services',
  title: 'Serviços',
  icon: 'fas fa-pencil-ruler',
}, {
  url: '/blog',
  title: 'Blog',
  icon: 'far fa-comment-alt',
}, {
  url: '/photos',
  title: 'Photos',
  icon: 'fas fa-camera',
}, {
  url: '/contact',
  title: 'Contato',
  icon: 'fas fa-mobile-alt',
}]
