export const TYPES = {
  site: 'site',
  hotsite: 'hotsite',
  casamento: 'casamento',
  convite: 'convite',
  retrospectiva: 'retrospectiva',
  success: 'success',
  fail: 'fail',
  loading: 'loading',
}
