export const serviceList = [ {
  description: `<p>Convite Virtual é uma modalidade diferente e dinâmica para convites. Um dos pontos fortes é que o convite pode ser criado e replicado facilitando muito mais a você que solicita e a quem vai receber, pode ser guardado eternamente, sem pesar, sem sujar, não precisando mandar novamente para gráfica, pode até mudar tudo de última hora!</p><p>As vantagens são inúmeras, um convite virtual pode ser enviado de diversas maneiras, pode ser uma imagem, enviada por Whatsapp, por e-mail, ou qualquer outra rede social. Pode ser um convite em formato one page na web, que pode ser compartilhado através de um link.</p><p>Tenho um exmplo de um convite virtual para um chá bar, veja abaxo</p><a href="http://jo.ivanoliveira.com.br/convite/EPSTA2.php" target="_blank">Convite de Chá Bar</a><p>Faça um teste, visualizando-o pelos principais dispositivos, Desktop (pc), Mobile e Tablet.</p>`,
  icon: 'fas fa-mail-bulk',
  id: 'convite-virtual',
  title: 'Convite Virtual',
}, {
  description: `<p>Domínio é um nome que serve para localizar e identificar conjuntos de computadores na Internet. O nome de domínio foi concebido com o objetivo de facilitar a memorização dos endereços de computadores na Internet. Sem ele, teríamos que memorizar uma sequência grande de números.</p><p>Quer registro sem complicação? Recomendo o <a href="http://registro.br" target="_blank">Registro BR</a>, lá você pesquisa um nome legal para seu site. Fale comigo, e resolvo a parte burocrática para você, já pensou em um nome?</p>`,
  icon: 'far fa-window-maximize',
  id: 'dominios',
  title: 'Domínios',
}, {
  description: `<p>Um serviço de hospedagem é basicamente onde seus arquivos produzidos para visualização do seu site, estão. Para que o seu site funcione corretamente, é necessário que todos os arquivos estejam a disposição para acesso. Todos os arquivos estão rodando em um disco virtual, logo, sempre que digita no endereço um site, ele busca através de um número de IP vinculado a este endereço onde estão os arquivos e exibindo-os em seu computador.</p><p>Hoje conto com um parceiro forte na questão de hospedagem, de confiança e bom preço. É a Parmenes IT. E além da hospedagem a Parmenes IT dispõe de serviços diversos para infraestrutura e internet.</p><p>Veja mais sobre a <a href="http://parmenes.com.br" target="_blank">Parmenes IT, clicando aqui</a></p>`,
  icon: 'fas fa-server',
  id: 'hospedagem',
  title: 'Hospedagem',
}, {
  description: `<p>Hotsite ou One Page é um site rápido, elaborado para o momento ou para poucas informações, voltado para destacar uma ação de comunicação e marketing pontual ou mesmo para dar sequencia á redes sociais.</p><p>Sua diferenciação está apenas na estratégia de comunicação utilizada para concebê-lo.</p><p>Geralmente os hotsites possuem um apelo visual maior e são mais focados em um público específico, traduzindo a expectativa deste alvo. Enquanto o One Page é um site de pouca informação visando gerar até certa curiosidade nas demais informações espalhadas em redes socais e blogs</p><p>Alguns dos hotsites proporcionam ao usuário interatividade com jogos, sendo assim a maior relação do "site e o usuário". O processo para criação e produção de um hotsite segue exatamente os mesmos direcionais de um website corporativo ou portal, considerando um conceito-criativo, arquitetura de informação, layouts, redação e programação.</p>`,
  icon: 'fas fa-fire-alt',
  id: 'hotsites',
  title: 'Hotsites',
}, {
  description: `<p>Sites institucionais, são páginas na internet de conteúdo estático, as vantagens são: a velocidade para carregamento das páginas será menor, um site mais rápido. Não utiliza banco de dados, aumentando a segurança da informação. Ele requer ajustes esporádicos, pois as suas informações vão ficando defasadas com o passar dos tempos.</p>`,
  icon: 'fas fa-columns',
  id: 'html5-site-estatico',
  title: 'HTML 5 - Site Estático',
}, {
  description: `<p>Mídia Digital é a junção de vários produtos que antes eram feitos em papel, ou mesmo fitas, cds, e dvds, mas que agora ficam em pen-drives, nuvens e servidores on-line.</p><p>Um exemplo simples são os convites. Muita gente está abrindo mão do papel e partindo para algo voltado para o virtual. Quem nunca recebeu um convite pelo "eventos" do Facebook? Ou de um grupo no WhatsApp? Existem convites gerais, que servem para todos, mas também temos a opção formal, e direta, um convite por pessoa, específico e único.</p>`,
  icon: 'fas fa-photo-video',
  id: 'midia-digital',
  title: 'Mídia Digital',
}, {
  description: `<p>Retrospectiva é o mais conhecido modelo de "presente" aos convidados. É o modo de mostrar toda uma história em pouco tempo. Normalmente utilizado em festas, de casamento e aniversário, ofereço dois modelos deles</p><p>O primeiro é o comum que acostumamos ver por aí, com boas imagens, com música de fundo, alguns textos e simples mas bonito de se ver. Veja abaixo um exemplo</p><a>Exemplo Restrospectiva Simples</a><br><iframe src="https://www.youtube.com/embed/4fmEZ9Tos2E?rel=0&amp;showinfo=0" frameborder="0" allowfullscreen></iframe><span class="space"></span><p>O segundo é mais detalhado, com narração, contando a história litealmente falando, com mensagens de humor, com desenhos animados. Este demanda mais tempo, por ser bem mais específico. O resultado fica bom. Vejam vocês mesmo.</p><a>Exemplo Restrospectiva com Narração </a><br><iframe src="https://www.youtube.com/embed/j3bBGQGrUj0?rel=0&amp;showinfo=0" frameborder="0" allowfullscreen></iframe>`,
  icon: 'fas fa-film',
  id: 'retrospectiva',
  title: 'Retrospectiva',
}, {
  description: `<p>RSVP é a abreviatura de Répondez S'il Vous Plaît, expressão francesa que significa "Responda por favor".</p><p>O RSVP é utilizado pela pessoa que deseja confirmar quem irá no evento que ela vai realizar, desta maneira pede a confirmação para ter um melhor planejamento do evento em geral.</p><p>Normalmente feito via telefone, o meu RSVP segue uma linha diferente, apenas por link, e-mail, onde o convidado entra no site e diz se vai ou não facilitando e diminuindo o valor do serviço que normalmente custa muito caro. São inúmeras a utilidades que se tem com o RSVP no seu evento, e sem a necessidade de uma telefonia.</p><p>Com o sistema de RSVP On-line, através de uma mensagem, você pode receber o SIM ou o NÃO do seu convidado e, saber quantas pessoas vão comparecer.</p><p>E aqui eu posso integrá-lo com o site do seu evento, ou hotsite.</p><p>Tenho também uma opção muito interessando de sites para casamento, onde os convidados tem acesso à lista de presentes, podem mandar recados aos noivos, falar se vão ou não, saber o local do casamento ou mesmo opinarem sobre as músicas a serem tocadas no dia da festa</p>`,
  icon: 'fas fa-user-check',
  id: 'rsvp',
  title: `RSVP - Répondez S'il Vous Plaît`,
}, {
  description: `<p>O site de casamento mais completo você vai encontrar aqui :) Todas as informações do casamento podem estar no site, desde informações básicas, como local do casamento, local da festa caso ela seja em endereço diferente, data e hora, lista de presente (link para as lista de presentes já definidas nas lojas). Até contador regressivo para data, seção de mensagem dos convidados (que os noivos escolhem se exibe ou não), músicas que os convidados podem dar pitaco para tocar no dia da festa.</p><p>Além do RSVP, se quiser saber mais sobre ele cliquem RSVP - Répondez S'il Vous Plaît no menu de serviços.</p><p>Veja um exemplo de aqui:</p><a href="http://casamento.ivanoliveira.com.br" target="_blank"> Site de Casamento</a>`,
  icon: 'fas fa-glass-cheers',
  id: 'site-de-casamento',
  title: 'Site de Casamento',
}, {
  description: `<p>Site institucional é uma página virtual dinâmica ou estática, que tem como principal objetivo fazer a divulgação da empresa, sendo o cartão de visitas no mundo virtual e a porta de entrada para bons negócios. Atualmente, ter um site deixou de ser um privilégio de poucos e tornou-se essencial para qualquer empresa e profissional liberal destacar seus serviços e diferenciais. Portanto fazer um site funcional e que atenda tantos as necessidades do cliente quanto do seu público-alvo é primordial para manter o bom relacionamento e na conquista de novos clientes.</p><p>Para entender melhor, é sempre bom saber, a base de um site, para não gerar dúvidas sobre os serviços prestados. Um site é feito a partir de ferramentas (linguagens de escrita e leitura exclusiva para cada tipo de função), disponível na internet.</p>`,
  icon: 'far fa-building',
  id: 'sites-institucionais',
  title: 'Sites Institucionais',
}, {
  description: `<p>Hoje em dia, ter um site na internet já deixou de ser uma opção e virou uma necessidade para empresas! Aliás, você sabia que a internet é o atual meio de comunicação que melhor combina preço, velocidade e eficiência na divulgação? Pois é, e estou aqui para te ajudar a não perder mais tempo!</p> <p>Mas o que tudo isso que eu falei tem a ver com criação? Tudo! Afinal, a criação envolve todo o primeiro contato com o cliente. Para que eu possa ter boas ideias, eu vou precisar de toda a informação sobre o serviço que você necessita, por este motivo, existe o "Briefing', que é dar informações e instruções concisas e objetivas sobre tarefa a ser executada. E as vezes é difícil explicar tudo o que queremos, e pensando nisso, criei as perguntas de briefing. Veja abaixo uma lista delas de acordo com o serviço desejado.</p>`,
  icon: 'fas fa-pencil-ruler',
  id: 'criacao',
  title: 'Criação',
} ]

export const menuService = [ {
  id: 'sites-institucionais',
  index: '0',
  itens: [ {
    id: 'html5-site-estatico',
    index: '0',
    title: 'HTML 5 - Site Estático',
  } ],
  title: 'Sites Institucionais',
}, {
  id: 'hotsites',
  index: '1',
  title: 'Hotsites',
}, {
  id: 'midia-digital',
  index: '2',
  itens: [ {
    id: 'convite-virtual',
    index: '0',
    title: 'Convite Virtual',
  }, {
    id: 'retrospectiva',
    index: '1',
    title: 'Retrospectiva',
  }, {
    id: 'site-de-casamento',
    index: '2',
    title: 'Site de Casamento',
  }, {
    id: 'rsvp',
    index: '3',
    title: `RSVP - Répondez S'il Vous Plaît`,
  } ],
  title: 'Mídia Digital',
}, {
  id: 'hospedagem',
  index: '3',
  title: 'Hospedagem',
}, {
  id: 'dominios',
  index: '4',
  title: 'Domínios',
}, {
  id: 'criacao',
  index: '5',
  title: 'Criação',
} ]
