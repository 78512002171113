import React, { useState } from 'react'
import PropTypes from 'prop-types'

const BriefingCasamento = (props) => {
  const {
    onSubmit,
  } = props

  const [form, setForm] = useState({})

  const onChangeInputValue = (e) => {
    const { value, name} = e.target

    setForm({
      ...form,
      [name]: value,
    })
  }

  const setupComponent = () => (`
    Qual o nome ou apelido dos noivos?<br> 
    ${form.nickNames || 'N/D'}<br><br>
    Sobre a hospedagem do site? <br>
    ${form.domain || 'N/D'}<br><br>
    Qual a data do casamento? <br>
    ${form.weddingDate || 'N/D'}<br><br>
    Qual o horário do casamento? <br>
    ${form.weddingTime || 'N/D'}<br><br>
    Qual o local do casamento? <br>
    ${form.weddingPlace || 'N/D'}<br><br>
    Quer o RSVP no site? <br>
    ${form.RSVP || 'N/D'}<br><br>
    Abrir comentários nas fotos? <br>
    ${form.comments || 'N/D'}<br><br>
    Já tem lista de presentes em algum lugar? <br>
    ${form.giftList || 'N/D'}
  `)

  const submitForm = (e) => {
    e.preventDefault()
    const content = setupComponent()
    const formContent = {
      briefing_type: 'Casamento',
      from_name: form.Name,
      reply_to: form.Email,
      briefing_contents: content,
    }

    onSubmit(formContent)
  }

  return (
    <form>
      <label className='label'>
        <span >Nome <b>*</b></span>
        <input
          name='Name'
          type='text'
          className='input'
          onChange={onChangeInputValue}/>
      </label>

      <label className='label'>
        <span>E-mail <b>*</b></span>
        <input
          name='Email'
          type='email'
          className='input'
          onChange={onChangeInputValue}/>
      </label>

      <label className='label'>
        <span>Qual o nome ou apelido dos noivos?</span>
        <input
          name='nickNames'
          type='text'
          className='input'
          onChange={onChangeInputValue}
        />
      </label>

      <div className='radio label'>
        <span>Sobre a hospedagem do site?</span>
        <label className='label-radio'>
          <input
            name='domain'
            value='Domínio próprio'
            type='radio'
            onChange={onChangeInputValue}
          />
          Tenho hospedagem e domínio próprios
        </label>
        <label className='label-radio'>
          <input
            name='domain'
            value='Não tenho domínio'
            type='radio'
            onChange={onChangeInputValue}
          />
          Não tenho hospedagem ou domínio
        </label>
      </div>

      <label className='label'>
        <span>Qual a data do casamento?</span>
        <input name='weddingDate'
          type='date'
          className='input'
          onChange={onChangeInputValue}
        />
      </label>

      <label className='label'>
        <span>Qual o horário do casamento?</span>
        <input
          name='weddingTime'
          type='time'
          className='input'
          onChange={onChangeInputValue}
        />
      </label>

      <label className='label'>
        <span>Qual o local do casamento?</span>
        <input
          name='weddingPlace'
          type='text'
          className='input'
          placeholder='ex. Avenida das Orquídeas, 256 - Jardins - São Paulo'
          onChange={onChangeInputValue}
        />
      </label>

      <div className='radio label'>
        <span>Quer o RSVP no site?</span>
        <label className='label-radio'>
          <input
            name='RSVP'
            value='Sim'
            type='radio'
            onChange={onChangeInputValue}
          />
          Sim
        </label>
        <label className='label-radio'>
          <input
            name='RSVP'
            value='Não' 
            type='radio'
            onChange={onChangeInputValue}
          />
          Não
        </label>
      </div>

      <div className='radio label'>
        <span>Abrir comentários nas fotos?</span>
        <label className='label-radio'>
          <input
            name='comments'
            value='Sim'
            type='radio'
            onChange={onChangeInputValue}
          />
          Sim
        </label>
        <label className='label-radio'>
          <input
            name='comments'
            value='Não'
            type='radio'
            onChange={onChangeInputValue}
          />
          Não
        </label>
      </div>

      <div className='radio label'>
        <span>Já tem lista de presentes em algum lugar?</span>
        <label className='label-radio'>
          <input
            name='giftList'
            value='Sim'
            type='radio'
            onChange={onChangeInputValue}
          />
          Sim
        </label>
        <label className='label-radio'>
          <input
            name='giftList'
            value='Não'
            type='radio'
            onChange={onChangeInputValue}
          />
          Não
        </label>
      </div>

      <button className='button' onClick={submitForm}>
        Enviar
      </button>
    </form>
  )
}

BriefingCasamento.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default BriefingCasamento
