import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { menuService } from '@/constants/mocks/serviceMock'
import { useNavigate } from 'react-router-dom'
import { StyledNav, StyleButtonMobile } from './styles'

const MenuService = (props) => {
  const {
    isMobile,
    onChange
  } = props

  const [showMenu, setShowMenu] = useState(false)
  const [currentTab, setCurrentTab] = useState('sites-institucionais')

  const navigate = useNavigate()

  const handleClick = (e, id) => {
    e.preventDefault()
    setShowMenu(false)
    setCurrentTab(id)
    onChange(id)
    navigate(`#${id}`)
  }

  const subMenuRender = (elem, state) => {
    if (elem.itens) {
      const subMenu = elem.itens.map((e, i) => {
        return (
          <li key={i}>
            <a
              id={e.id}
              onClick={(item) => handleClick(item, e.id)}
              className={(e.id === state) ? 'active' : ''}
            >
              <i className="fas fa-angle-double-right" />
              {e.title}
            </a>
          </li>
        )
      })
      return (
        <ul className="submenu">
          {subMenu}
        </ul>
      )
    }
  }

  const renderMenu = (elem, index) => (
    <li key={index}>
      <a
        className={(elem.id === currentTab) ? 'active' : ''}
        onClick={(e) => handleClick(e, elem.id)}
        id={elem.id}
      >
        <i className="fas fa-angle-right" />
        {elem.title}
      </a>
      {subMenuRender(elem, currentTab)}
    </li>
  )

  return (
    <>
      {isMobile && (
        <StyleButtonMobile onClick={() => setShowMenu(!showMenu)}>
          <span>Ver mais serviços <i className="fas fa-list" /></span>
        </StyleButtonMobile>
      )}
      {(!isMobile || showMenu) && (
        <StyledNav>
          <ul>
            {menuService.map(renderMenu)}
          </ul>
        </StyledNav>
      )}
    </>
  )
}

MenuService.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default MenuService
