import React from 'react'
import { socialMedia } from '@/constants/mocks/footerContent'
import { FooterStyled } from './styles'

const Footer = () => {

  const renderComponent = (media, i) => (
    <a
      href={media.url}
      key={i}
      rel="noreferrer"
      target="_blank"
      title={media.name}
      alt={media.name}
    >
      <i className={media.icon} />
    </a>
  )

  return (
    <FooterStyled>
      <div className="esq">
        <p>Há três métodos para ganhar sabedoria: primeiro, por reflexão, que é o mais nobre; segundo, por imitação, que é o mais fácil; e terceiro, por experiência, que é o mais amargo.</p>
        <p>(CONFÚCIO)</p>
      </div>
      
      <div className="redesSociais">
        <p>Siga-me nas redes sociais:</p>
        {socialMedia.map((media, i) => renderComponent(media, i))}
      </div>
    </FooterStyled>
  )
}

export default Footer
