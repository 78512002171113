import React, { useState } from 'react'
import PropTypes from 'prop-types'

const BriefingHotsite = (props) => {
  const {
    onSubmit,
  } = props

  const [form, setForm] = useState({})

  const onChangeInputValue = (e) => {
    const { value, name} = e.target

    setForm({
      ...form,
      [name]: value,
    })
  }

  const setupComponent = () => (`
    Qual o nome do seu site?<br> 
    ${form.siteName || 'N/D'}<br><br>
    Este site já tem um domínio próprio? <br>
    ${form.domain || 'N/D'}<br><br>
    Este site já tem hospedagem? <br>
    ${form.host || 'N/D'}<br><br>
    Já existe uma arte de logo para o site? <br>
    ${form.logo || 'N/D'}<br><br>
    Possui layout pronto? <br>
    ${form.layout || 'N/D'}<br><br>
    Tem algum site na internet que queira usar como exemplo para o layout do seu? <br>
    ${form.layoutExample || 'N/D'}<br><br>
    Por quanto tempo vai durar esta promoção / evento / divulgação? <br>
    ${form.howLong || 'N/D'}<br><br>
    Já possui as informações para o conteúdo? <br>
    ${form.contentInfo || 'N/D'}
  `)

  const submitForm = (e) => {
    e.preventDefault()
    const content = setupComponent()
    const formContent = {
      briefing_type: 'Hotsite',
      from_name: form.Name,
      reply_to: form.Email,
      briefing_contents: content,
    }

    onSubmit(formContent)
  }

  return (
    <form>
      <label className='label'>
        <span >Nome <b>*</b></span>
        <input
          name='Name'
          type='text'
          className='input'
          onChange={onChangeInputValue}/>
      </label>

      <label className='label'>
        <span>E-mail <b>*</b></span>
        <input
          name='Email'
          type='email'
          className='input'
          onChange={onChangeInputValue}/>
      </label>

      <label className='label'>
        <span>Qual o nome do seu site?</span>
        <input
          name='SiteName'
          type='text'
          className='input'
          onChange={onChangeInputValue}/>
      </label>

      <div className='radio label'>
        <span>Este site já tem um domínio próprio?</span>
        <label className='label-radio'>
          <input
            name='domain'
            value='Sim'
            type='radio'
            onChange={onChangeInputValue}/>
          Sim
        </label>
        <label className='label-radio'>
          <input
            name='domain'
            value='Não'
            type='radio'
            onChange={onChangeInputValue}/>
          Não
        </label>
      </div>

      <div className='radio label'>
        <span>Este site já tem hospedagem?</span>
        <label className='label-radio'>
          <input
            name='host'
            value='Sim'
            type='radio'
            onChange={onChangeInputValue}/>
          Sim
        </label>
        <label className='label-radio'>
          <input
            name='host'
            value='Não'
            type='radio'
            onChange={onChangeInputValue}/>
          Não
        </label>
      </div>

      <div className='radio label'>
        <span>Já existe uma arte de logo para o site?</span>
        <label className='label-radio'>
          <input
            name='logo'
            value='Sim'
            type='radio'
            onChange={onChangeInputValue}/>
          Sim
        </label>
        <label className='label-radio'>
          <input
            name='logo'
            value='Não'
            type='radio'
            onChange={onChangeInputValue}/>
          Não
        </label>
      </div>

      <div className='radio label'>
        <span>Possui layout pronto?</span>
        <label className='label-radio'>
          <input
            name='layout'
            value='Sim'
            type='radio'
            onChange={onChangeInputValue}/>
          Sim
        </label>
        <label className='label-radio'>
          <input
            name='layout'
            value='Não'
            type='radio'
            onChange={onChangeInputValue}/>
          Não
        </label>
      </div>

      <label className='label'>
        <span>Tem algum site na web que queira usar como exemplo para o layout do seu?</span>
        <input
          name='layoutExample'
          type='text'
          className='input'
          onChange={onChangeInputValue}/>
      </label>

      <label className='label'>
        <span>Por quanto tempo vai durar esta promoção / evento / divulgação?</span>
        <input
          name='howLong'
          type='text'
          className='input'
          onChange={onChangeInputValue}/>
      </label>

      <div className='radio label'>
        <span>Já possui as informações para o conteúdo?</span>
        <label className='label-radio'>
          <input
            name='contentInfo'
            value='Sim'
            type='radio'
            onChange={onChangeInputValue}/>
          Sim
        </label>
        <label className='label-radio'>
          <input
            name='contentInfo'
            value='Não'
            type='radio'
            onChange={onChangeInputValue}/>
          Não
        </label>
      </div>

      <button className='button' onClick={submitForm}>
        Enviar
      </button>
    </form>
  )
}

BriefingHotsite.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default BriefingHotsite
